(function() {
    //'use strict';

    angular
        .module('ordiniApp')
        .controller('EntityRejectController',EntityRejectController);

    EntityRejectController.$inject = ['$uibModalInstance', '$filter', 'controller', 'entityName'];

    function EntityRejectController($uibModalInstance, $filter, controller, entityName) {
        var vm = this;

        vm.motivazioneRifiuto = "";
        vm.clear = clear;
        vm.confirm = confirm;
        vm.controller = controller;
        vm.entityName = entityName;
        vm.error = null;
        vm.motivazioneRifiutoMaxLength=1000; // lunghezza del campo motivazioneRifiuto
        vm.dichiarazioneMotivazioneRifiutoMaxLength=10000; // lunghezza del campo motivazioneRifiuto dell'entità entityName
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm (id) {
        	
        	var localDate = new Date;
        	
        	data = $filter('date')(localDate, "dd/MM/yyyy HH:mm:ss");
        	
        	var tipoRifiuto="RIFIUTATO_DA_AMMINISTRAZIONE";
        	if (vm.controller[vm.entityName].stato=="INVIATO_A_DIRETTORE")
        		tipoRifiuto="RIFIUTATO_DA_DIRETTORE";
        	else if (vm.controller[vm.entityName].stato=="INVIATO_A_RESPONSABILE")
        		tipoRifiuto="RIFIUTATO_DA_RESPONSABILE";
        	
        	var motivazioni="";
        	if (vm.controller[vm.entityName].motivazioneRifiuto!=null)
        		motivazioni="\n\n"+vm.controller[vm.entityName].motivazioneRifiuto;
        	
        	var newMotivazione =
        		tipoRifiuto+" in data "+data+" con motivazione:\n"+ 	
        		vm.motivazioneRifiuto+
        		motivazioni;

        	if (vm.motivazioneRifiuto.length==0) {
        		vm.error="E' necessario inserire la motivazione del rifiuto.";
        	} else if (vm.motivazioneRifiuto.length>vm.motivazioneRifiutoMaxLength || newMotivazione.length>vm.dichiarazioneMotivazioneRifiutoMaxLength) {
        		vm.error="La motivazione rifiuto inserita è troppo grande, provare a diminuire la lunghezza del testo.";
        	} else {
        		vm.controller[vm.entityName].motivazioneRifiuto=newMotivazione;

            	$uibModalInstance.close(true);
        	}
        }
    }
})();
