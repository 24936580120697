(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('DecisioneImpegniController', DecisioneImpegniController);

    DecisioneImpegniController.$inject = ['$scope', '$state', '$uibModal', '$uibModalInstance', 'Impegno', 'VoceBilancio', 'ImpegnoDBSearch', 'ParseLinks', 'AlertService', 'decisione'];

    function DecisioneImpegniController ($scope, $state, $uibModal, $uibModalInstance, Impegno, VoceBilancio, ImpegnoDBSearch, ParseLinks, AlertService, decisione) {
        var vm = this;
        
        vm.decisione = decisione;
    	vm.esercizio = esercizio;
    	vm.esercizioVoce = esercizioVoce;
        vm.vocebilancios = VoceBilancio.query({size:100000000, filterOn: true, viewableOnly: true, esercizio: vm.esercizioVoce()}); // inca$ - caricamento totale liste fk
        vm.itemsPerPage = 1000000;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.save = save;
        vm.impegni = null;
        vm.impegniItems = null;
        vm.impegniSelectedItems = [];

        loadAll();

        $scope.$watch('vm.impegniItems', function(newValues){
        	vm.impegniSelectedItems.length = 0;
            angular.forEach(newValues, function(item) {
                if (item.value == true) {
                	vm.impegniSelectedItems.push(item);
                }
            });
            //console.log(vm.impegniSelectedItems);
        }, true);

        function loadAll () {
            ImpegnoDBSearch.query({
            	param: {responsabileGaeId: vm.decisione.impegno.gae.finanziamento.responsabileFondi.id}
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.impegni = data;
                vm.impegniItems=[];
                for(var i=0; i < vm.totalItems; i++) {
                	// attenzione, inserisco solo impegni aggiuntivi nell'esercizio attuale!!!
                	if (vm.impegni[i].esercizio==vm.esercizio())
                		vm.impegniItems = vm.impegniItems.concat([{ impegno : vm.impegni[i], value: false}]);
                }
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function clear () {
        	$uibModalInstance.dismiss('cancel');
        }

        function save () {

            var totalItems = vm.impegniSelectedItems.length;
            vm.selCounter=totalItems;
            var impegniItems=[];
            for(var i=0; i < totalItems; i++) {
            	var impegno = vm.impegniSelectedItems[i].impegno;
            	impegno.decisione=vm.decisione;
            	Impegno.update(impegno, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
        	modalScrollTop();
    		vm.selCounter--;
    		if (vm.selCounter==0) {
                $uibModalInstance.close(true);
    		}
        }

        function onSaveError () {
        	modalScrollTop();
        }
    
        function esercizio () {
        	if (vm.decisione!=null && vm.decisione.data!=null)
        		return vm.decisione.data.getFullYear();
        	return (new Date()).getFullYear();
        }

        function esercizioVoce () {
        	if (vm.decisione!=null && vm.decisione.data!=null)
        		return vm.decisione.data.getFullYear();
        	return (new Date()).getFullYear();
        }

    }
})();
