(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('StorageController', StorageController);

    StorageController.$inject = ['$filter', '$scope', '$uibModal', 'StorageService', 'ParseLinks'];

    function StorageController ($filter, $scope, $uibModal, StorageService, ParseLinks) {
        var vm = this;

        vm.storage = null;
        vm.storageItems = null;
        vm.storageSelectedItems = [];
        vm.fromDate = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.onChangeDate = onChangeDate;
        vm.page = 1;
        vm.previousMonth = previousMonth;
        vm.toDate = null;
        vm.today = today;
        vm.totalItems = null;

        vm.go=go;
        
        vm.today();
        vm.previousMonth();
        vm.onChangeDate();

        $scope.$watch('vm.storageItems', function(newValues){
        	vm.storageSelectedItems.length = 0;
            angular.forEach(newValues, function(item) {
                if (item.value == true) {
                	vm.storageSelectedItems.push(item);
                }
            });
            //console.log(vm.storageSelectedItems);
        }, true);
        
        
        function onChangeDate () {
            var dateFormat = 'yyyy-MM-dd';
            var fromDate = $filter('date')(vm.fromDate, dateFormat);
            var toDate = $filter('date')(vm.toDate, dateFormat);

            StorageService.query({page: vm.page -1, size: 20, fromDate: fromDate, toDate: toDate}, function(result, headers){
                vm.storage = result;
                vm.links = ParseLinks.parse(headers('link'));
            	vm.totalItems = headers('X-Total-Count');
            	var pageItems = vm.storage.length;
                vm.storageItems=[];
                for(var i=0; i < pageItems; i++) {
                	vm.storageItems = vm.storageItems.concat([{ decisione : vm.storage[i], value: false}]);
                }
            });
        }

        // Date picker configuration
        function today () {
            // Today + 1 day - needed if the current day must be included
            var today = new Date();
            vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
        }

        function previousMonth () {
            var fromDate = new Date();
            if (fromDate.getMonth() === 0) {
                fromDate = new Date(fromDate.getFullYear() - 1, 11, fromDate.getDate());
            } else {
                fromDate = new Date(fromDate.getFullYear(), fromDate.getMonth() - 1, fromDate.getDate());
            }

            vm.fromDate = fromDate;
        }

        function loadPage (page) {
            vm.page = page;
            vm.onChangeDate();
        }

        function go (goType) {
        	
	        $uibModal.open({
	            templateUrl: 'app/admin/storage/storage-dialog.html',
	            controller: 'StorageDialogController',
	            controllerAs: 'vm',
	            size: 'sm',
	            resolve: {
	                controller: function() {
	                    return vm;
	                },
	        		goType: function() {
	                    return goType;
	                }
	            }
	        }).result.finally(function () {
	            $state.go('^');
	        });
        }
    }
})();
