(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('OperazioneDialogController', OperazioneDialogController);

    OperazioneDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Operazione', 'RuoloFunzione'];

    function OperazioneDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Operazione, RuoloFunzione) {
        var vm = this;

        vm.operazione = entity;
        vm.clear = clear;
        vm.save = save;
        vm.ruolofunziones = RuoloFunzione.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.operazione.id !== null) {
                Operazione.update(vm.operazione, onSaveSuccess, onSaveError);
            } else {
                Operazione.save(vm.operazione, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ordiniApp:operazioneUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
