(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .directive('miSearch', miSearch);

    function miSearch() {
        var directive = {
            restrict: 'A',
            scope: {
                fieldType: "@fieldType",
                fieldDomain: "@fieldDomain"
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs, parentCtrl) {
            element.bind('click', function () {
            	var elem = element.parent().children().first()[0];
            	var field = elem.attributes['jh-sort-by'].value;
            	var type = scope.fieldType;
            	var domain = scope.fieldDomain;
            	scope.$parent.vm.searchForm(field,type,domain);
            });
        }
    }
    
})();
