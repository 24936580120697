(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('OrdineDialogController', OrdineDialogController);

    OrdineDialogController.$inject = ['$filter', '$window', '$timeout', '$scope', '$rootScope', '$stateParams', '$uibModal', '$uibModalInstance', '$q', 'entity', 'DettaglioOrdineDBSearch', 'Permissions', 'Currencies', 'MailSigns', 'DataUtils', 'Ordine', 'Documento', 'Dichiarazione', 'Decisione', 'DettaglioOrdine', 'DocumentoFile', 'AdminCommands'];

    function OrdineDialogController ($filter, $window, $timeout, $scope, $rootScope, $stateParams, $uibModal, $uibModalInstance, $q, entity, DettaglioOrdineDBSearch, Permissions, Currencies, MailSigns, DataUtils, Ordine, Documento, Dichiarazione, Decisione, DettaglioOrdine, DocumentoFile, AdminCommands) {
        var vm = this;

        // inca$ - variabili gestione preview e invio
        vm.visualizza = false;
        vm.invia = false;
        vm.isPreview = false; // quando viene premuto Preview, isPreview viene settata definitivamente a true;
        vm.isValidating = false; // quando viene premuto Valida o Respingi, isValidating viene settato definitivamente a true;
        vm.oldStato;
        vm.dateformat='dd/MM/yyyy';
        $scope.functionNameForAutocomplete = 'ordine'; // per gestire l'autocompletamento
        vm.numeroDecimaliImporto=2;

        vm.ordine = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.downloadFile = DataUtils.downloadFile;
        vm.previewFile = DataUtils.previewFile;
        vm.openStorageFile = openStorageFile;
        vm.downloadStorageFile = downloadStorageFile;
        vm.docFileName = docFileName;
        vm.save = save;
        vm.save2 = save2;
        vm.save3 = save3;
    	vm.utenteLogin = Permissions.getAccount().login;
//        vm.documentopdfs = Documento.query({filter: 'ordinepdf-is-null'});
//        $q.all([vm.ordine.$promise, vm.documentopdfs.$promise]).then(function() {
//            if (!vm.ordine.documentoPdf || !vm.ordine.documentoPdf.id) {
//                return $q.reject();
//            }
//            return Documento.get({id : vm.ordine.documentoPdf.id}).$promise;
//        }).then(function(documentoPdf) {
//            vm.documentopdfs.push(documentoPdf);
//        });
        vm.dichiaraziones = Dichiarazione.query({filter: 'ordine-is-null', valid: true});
        $q.all([vm.ordine.$promise, vm.dichiaraziones.$promise]).then(function() {
            if (!vm.ordine.dichiarazione || !vm.ordine.dichiarazione.id) {
                return $q.reject();
            }
            return Dichiarazione.get({id : vm.ordine.dichiarazione.id}).$promise;
        }).then(function(dichiarazione) {
            vm.dichiaraziones.push(dichiarazione);
        });
        //vm.dettaglioordines = DettaglioOrdine.query({size:100000000}); // inca$ - caricamento totale liste fk
        // inca$ - gestione validazione e rifiuto
        vm.validate = validate;
        vm.reject = reject;
        vm.disableSave = disableSave; // inca$ - imposta l'abilitazione di Salva
        vm.disableValidateOrReject = disableValidateOrReject; // inca$ - imposta l'abilitazione dei button Valida e Respingi
        vm.disablePreviewOrSend = disablePreviewOrSend;
        vm.showMotivazioneRifiuto = showMotivazioneRifiuto;
        vm.showInformazioniAmministrazione = showInformazioniAmministrazione;
        vm.disableInformazioniAmministrazione = disableInformazioniAmministrazione;
        vm.showProtocollo = showProtocollo;
        vm.disableProtocollo = disableProtocollo;
        // inca$ - aggiunta gestione rifiuto
        vm.rejectDialogForm = rejectDialogForm;
        vm.eraseHiddenFields = eraseHiddenFields;
        // inca$ - gestione dettaglio
        vm.dettagliOrdine = null;
        vm.findDettagliOrdine = findDettagliOrdine;
        vm.addDettaglioOrdine = addDettaglioOrdine;
        vm.editDettaglioOrdine = editDettaglioOrdine;
        vm.deleteDettaglioOrdine = deleteDettaglioOrdine;
        vm.disableInsertDetails = disableInsertDetails;
        // inca$ - gestione totali dettagli
        vm.totaleImponibile = 0;
        vm.totaleIva = 0;
        vm.totaleOrdine = 0;
        vm.calcTotaliDattaglio=calcTotaliDattaglio;
        vm.percentualeIvaInit=percentualeIvaInit;
        // inca$ - gestione currencies
        vm.currencies = null;
        // inca$ - gestione invio mail documenti
        vm.sendMailSigns = sendMailSigns;
        vm.viewMailSigns = viewMailSigns;
        vm.mailSent = mailSent;
        //inca$ - gestione fuzioni superutente
        vm.viewUpdateStato = viewUpdateStato;
        vm.updateStato = updateStato;
        vm.statoForUpdate = vm.ordine.stato;
        vm.updateStatoDone = updateStatoDone;
        vm.currencySymbol = currencySymbol;
        vm.dichiarazioneChanged = dichiarazioneChanged;
        vm.editDichiarazione = editDichiarazione;
        vm.editDecisione = editDecisione;
        vm.decisioneEsteso = null;
        vm.refreshDecisioneEsteso = refreshDecisioneEsteso;
        // inca$ - aggiunta gestione invio multiplo
        vm.sendDialogForm = sendDialogForm;
        // inca$ - gestione stato colorato
        vm.statoColour = statoColour;
        vm.statoLinkedColour = statoLinkedColour;
        vm.disablePercentualeIva = disablePercentualeIva;
        vm.printIvaOnDetails = printIvaOnDetails;
        
    	// all'ingresso della funzione, consideriamo lo stato
    	// di rifiutato come se fosse in compilazione
        {
       		// inca$ - gestione chiusura e invio
        	if ((vm.ordine.stato == 'RIFIUTATO_DA_AMMINISTRAZIONE' ||
            	vm.ordine.stato == 'RIFIUTATO_DA_RESPONSABILE' ||
            	vm.ordine.stato == 'RIFIUTATO_DA_DIRETTORE') &&
        		((vm.ordine.utenteCreazione == vm.utenteLogin ||
        		vm.ordine.dichiarazione.decisione.responsabileProcedimento.user.login == vm.utenteLogin) ||
        		Permissions.hasRole('ROLE_SUPERUTENTE'))) {
				vm.ordine.stato = 'IN_COMPILAZIONE';
				//vm.ordine.file = null;
        	}
        }
        
        function openStorageFile (documento) {
        	var	type = documento.fileContentType;
        	var data = documento.file;
        	
        	if (documento.simpleStorageService) {
        		DocumentoFile.get({id : documento.id}, function(result) {
        			console.log(result.fileContentType);
        			//console.log(result.file);
         			vm.openFile(result.fileContentType, result.file);
                }, onGetFileError);
        	} else
            	vm.openFile(type, data);
        }

        function downloadStorageFile (documento, filename) {
        	var	type = documento.fileContentType;
        	var data = documento.file;

        	if (documento.simpleStorageService) {
        		DocumentoFile.get({id : documento.id}, function(result) {
        			console.log(result.fileContentType);
        			//console.log(result.file);
         			vm.downloadFile(result.fileContentType, result.file, filename);
                }, onGetFileError);
        	} else
            	vm.downloadFile(type, data, filename);
        }

        function onGetFileError () {
        	modalScrollTop();
        };	

        function statoLinkedColour (stato, tipoDocumento, classField) {
        	if (stato=='IN_COMPILAZIONE')
        		classField = classField+" stato-field-compilatore";
        	else if (stato=='INVIATO') {
        		if (tipoDocumento=='decisione')
        			classField = classField+" stato-field-amministrazione";
        		else
        			classField = classField+" stato-field-responsabile";
        	}
        	else if (stato=='RIFIUTATO_DA_RESPONSABILE')
        		classField = classField+" stato-field-rifiuto";
        	else if (stato=='INVIATO_A_AMMINISTRAZIONE')
        		classField = classField+" stato-field-amministrazione";
        	else if (stato=='RIFIUTATO_DA_AMMINISTRAZIONE')
        		classField = classField+" stato-field-rifiuto";
        	else if (stato=='INVIATO_A_DIRETTORE')
        		classField = classField+" stato-field-direttore";
        	else if (stato=='RIFIUTATO_DA_DIRETTORE')
        		classField = classField+" stato-field-rifiuto";
        	else if (stato=='VALIDATO')
        		classField = classField+" stato-field-completato";
        	return classField;
        }

        function statoColour (stato) {
        	//return "stato-visibile-field form-control"; // default
        	return statoLinkedColour (stato, 'ordine', "stato-visibile-field");
        }
        
        // inca$ - caricamento file solo se da DB
        {
        	if (entity.documentoPdf!=null&&!entity.documentoPdf.simpleStorageService)
	    		DocumentoFile.get({id : entity.documentoPdf.id}, function(result) {
	     			vm.ordine.documentoPdf["file"]=result.file;
	            });
        }

        // inca$ - inizializzazione caricamento ruoli
        Permissions.loadRoles();

        // inca$ - inizializzazione caricamento currencies
        Currencies.loadCurrencyList(vm);

        // inca$ - carico i documenti allegati
        vm.findDettagliOrdine();
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
            
            if (vm.ordine.dichiarazione.decisione.id!=null) {
            	refreshDecisioneEsteso ();
            }
        });

        function refreshDecisioneEsteso () {
        	if (vm.ordine.dichiarazione!=null && vm.ordine.dichiarazione.decisione!=null)
        		vm.decisioneEsteso="ID: "+vm.ordine.dichiarazione.decisione.id+" - "+vm.ordine.dichiarazione.decisione.impegno.codice+" - "+vm.ordine.dichiarazione.decisione.stato;
        	else
        		vm.decisioneEsteso="";
        }
        
        function clear () {
        	// inca$ - chiudiamo solo se non è stata fatta preview
        	if (!vm.isPreview)
        		$uibModalInstance.dismiss('cancel');
        }

        function save () {

        	vm.eraseHiddenFields();

       		// memorizziamo SEMPRE lo stato precedente al salvataggio
       		vm.oldStato = vm.ordine.stato;

        	// inca$ - gestione chiusura e invio
        	if (vm.ordine.stato==null)
        		vm.ordine.stato='IN_COMPILAZIONE';

        	if (vm.invia==true) {
        		
        		// se si volesse inviare direttamente senza dialog di invio multiplo
        		// basta attivare save2() e disattivare sendDialogForm(); 
        		// save2(); 
                sendDialogForm();

        	} else
                save3();
        }

        function save2 () {
        	if (vm.ordine.stato=='IN_COMPILAZIONE')
    			if (vm.ordine.dichiarazione.decisione.impegno.gae.finanziamento.responsabileFondi.direttore == true ||
    				vm.ordine.dichiarazione.decisione.impegno.gae.finanziamento.responsabileFondi.user.login == vm.utenteLogin)
    				vm.ordine.stato='INVIATO_A_AMMINISTRAZIONE';
    			else
    				vm.ordine.stato='INVIATO';
            vm.invia = false;
        }

        function save3 () {
	        vm.isSaving = true;
	        if (vm.ordine.id !== null) {
				if (vm.ordine.documentoPdf != null &&
						(vm.ordine.documentoPdf.annoProtocollo != null ||
						vm.ordine.documentoPdf.numeroProtocollo != null)) {
					Documento.update(vm.ordine.documentoPdf);
				}
	            Ordine.update(vm.ordine, onSaveSuccess, onSaveError);
	        } else {
	            Ordine.save(vm.ordine, onSaveSuccess, onSaveError);
	        }
        }

        function onSaveSuccess (result) {
			// inca$ - gestione preview
			if (vm.visualizza == true) {
				vm.ordine=result;
				$scope.editForm.$setValidity();
	            $scope.$emit('ordiniApp:ordineUpdate', result);
	        	// inca$ - non più funzionante su chrome a causa dell'errore
	        	// Not allowed to navigate top frame to data URL
				//$window.open('data:' + result.documentoPdf.fileContentType + ';base64,' + result.documentoPdf.file);
	            var type = result.documentoPdf.fileContentType;
	            var data = result.documentoPdf.file;
	        	var win = window.open();
	        	vm.previewFile(type, data);

				// sono costretto a settare il valore delle date a causa di un azzeramento per il comportamento anomalo
				// della proprietà uib-datepicker-popup="{{vm.dateformat}}"
				var data = angular.element(document.querySelector('#field_data'));
				data.val($filter('date')(result.data,vm.dateformat));
				var dataOfferta = angular.element(document.querySelector('#field_dataOfferta'));
				dataOfferta.val($filter('date')(result.dataOfferta,vm.dateformat));

                vm.visualizza=false;
			} else {
	            $scope.$emit('ordiniApp:ordineUpdate', result);
	            $uibModalInstance.close(result);
			}
            vm.isSaving = false;
        }

        function onSaveError () {
        	// inca$ - mia funzione per fare scroll to top
        	modalScrollTop();

            vm.isSaving = false;
        	// inca$ - gestione preview
       		vm.isPreview=false;

       		// torniamo SEMPRE allo stato precedente in caso di errore
       		vm.ordine.stato = vm.oldStato;

       		// inca$ - gestione valida o respingi
			if (vm.isValidating == true) {
    			//vm.ordine.stato = vm.oldStato;
				vm.isValidating = false;
			}
        }

    	// inca$ - gestione preview
        vm.preview = function () {
        	vm.visualizza=true;
        	vm.isPreview = true;
        }

        // inca$ - gestione invio
        vm.send = function () {
        	vm.invia=true;
        }

        vm.setFile = function ($file, ordine) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        ordine.file = base64Data;
                        ordine.fileContentType = $file.type;
                    });
                });
            }
        };
        vm.datePickerOpenStatus.dataProtocolloOrdine = false;
        vm.datePickerOpenStatus.dataOfferta = false;
        vm.datePickerOpenStatus.dataCreazione = false;
        vm.datePickerOpenStatus.dataAggiornamento = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function validate () {

            // inca$ - gestione validazione
        	if (vm.ordine.stato == 'INVIATO') {
    			vm.ordine.stato = 'INVIATO_A_AMMINISTRAZIONE';

    			vm.isValidating = true;
    			vm.oldStato = 'INVIATO';
    			vm.isSaving = true;
        		Ordine.update(vm.ordine, onSaveSuccess, onSaveError);

			} else if (vm.ordine.stato == 'INVIATO_A_AMMINISTRAZIONE') {
				vm.ordine.stato = 'INVIATO_A_DIRETTORE';

				vm.isValidating = true;
				vm.oldStato = 'INVIATO_A_AMMINISTRAZIONE';
				vm.isSaving = true;
				Ordine.update(vm.ordine, onSaveSuccess, onSaveError);

			} else if (vm.ordine.stato == 'INVIATO_A_DIRETTORE') {
				vm.ordine.stato = 'VALIDATO';
	
				vm.isValidating = true;
				vm.oldStato = 'INVIATO_A_DIRETTORE';
				vm.isSaving = true;
				Ordine.update(vm.ordine, onSaveSuccess, onSaveError);
			}
        }

        function reject () {

        	// inca$ - gestione rifiuto
    		if (vm.ordine.stato == 'INVIATO') {
    			vm.ordine.stato = 'RIFIUTATO_DA_RESPONSABILE';

    			vm.isValidating = true;
    			vm.oldStato = 'INVIATO';
    			vm.isSaving = true;

    			Ordine.update(vm.ordine, onSaveSuccess, onSaveError);

    		} else if (vm.ordine.stato == 'INVIATO_A_AMMINISTRAZIONE') {
				vm.ordine.stato = 'RIFIUTATO_DA_AMMINISTRAZIONE';

				vm.isValidating = true;
				vm.oldStato = 'INVIATO_A_AMMINISTRAZIONE';
				vm.isSaving = true;
				
				Ordine.update(vm.ordine, onSaveSuccess, onSaveError);

			} else if (vm.ordine.stato == 'INVIATO_A_DIRETTORE') {
				vm.ordine.stato = 'RIFIUTATO_DA_DIRETTORE';

				vm.isValidating = true;
				vm.oldStato = 'INVIATO_A_DIRETTORE';
				vm.isSaving = true;
				
				Ordine.update(vm.ordine, onSaveSuccess, onSaveError);

			}


        }

        // inca$ - gestione save, preview o send
        function disableSave () {

        	if (vm.ordine.stato == null) {
        		return false;
        	}

        	// permetto l'amministrazione di inserire il protocollo
        	if (!disableProtocollo())
        		return false;

        	return disableSavePreviewOrSend();
        }

        // inca$ - gestione inserimento allegati e dettagli
        function disableInsertDetails () {

        	if (disableSave())
        		return true;
        	
        	if (vm.ordine.stato == null) {
        		return true;
        	}
			if (vm.ordine.stato != 'IN_COMPILAZIONE') {
				return true;
			} else {
				return false;
			}

        }

        // inca$ - gestione save, preview o send
        function disablePreviewOrSend () {

        	if (vm.ordine.stato == null) {
        		return true;
        	}
        	if (vm.dettagliOrdine == null || vm.dettagliOrdine.length==0) {
        		return true;
        	}
        	return disableSavePreviewOrSend();

        }

        // inca$ - gestione save, preview o send
        function disableSavePreviewOrSend () {

           	// ricordiamoci che il direttore potrebbe essere che sia lui a creare la decisione di contrattare
           	// oppure sia lui il responsabile del procedimento, pertanto va prima verificato questa condizione
           	// successivamente quella del ruolo direttore
           	if (vm.ordine.dichiarazione.decisione.responsabileProcedimento.user.login == vm.utenteLogin ||
				vm.ordine.utenteCreazione == vm.utenteLogin||
				Permissions.hasRole('ROLE_SUPERUTENTE')) {

				if ((vm.ordine.stato == 'INVIATO')||
						(vm.ordine.stato == 'INVIATO_A_AMMINISTRAZIONE')||
						(vm.ordine.stato == 'INVIATO_A_DIRETTORE')||
						(vm.ordine.stato == 'VALIDATO')) {
					return true;
				} else {
					return false;
				}
    		} else if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE']) &&
        			vm.ordine.stato == 'VALIDATO')) {
       			return true;
    		}

       		return true;
        }

        // inca$ - gestione valida o respingi
        function disableValidateOrReject () {

			if (vm.ordine.stato == null)
				return true;

    		if ((((Permissions.hasOneOfRoles(['ROLE_SUPERUTENTE']) || vm.ordine.dichiarazione.decisione.impegno.gae.finanziamento.responsabileFondi.user.login == vm.utenteLogin)) && vm.ordine.stato == 'INVIATO') ||
       			(Permissions.hasOneOfRoles(['ROLE_SUPERUTENTE','ROLE_AMMINISTRAZIONE']) && vm.ordine.stato == 'INVIATO_A_AMMINISTRAZIONE') ||
       			(Permissions.hasOneOfRoles(['ROLE_SUPERUTENTE','ROLE_DIRETTORE']) && vm.ordine.stato == 'INVIATO_A_DIRETTORE')) {
    			return false;
    		}
       		return true;
        }

        // inca$ - gestione campo motivazione rifiuto
        function showMotivazioneRifiuto () {

			if (vm.ordine.stato == null)
				return false;

			if (vm.ordine.motivazioneRifiuto != null)
				return true;

			if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_DIRETTORE','ROLE_SUPERUTENTE']) ||
					(vm.ordine.stato == 'RIFIUTATO_DA_AMMINISTRAZIONE' || vm.ordine.stato == 'RIFIUTATO_DA_RESPONSABILE' || vm.ordine.stato == 'RIFIUTATO_DA_DIRETTORE')))
    			return true;

			return false;
        }

        // inca$ - gestione campo protocollo
        function showInformazioniAmministrazione () {
        	//return true;
			if (vm.ordine.stato == null)
				return false;
			
			if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_SUPERUTENTE']) ||
					vm.ordine.codiceTerzoFornitore !=null ||
					vm.ordine.dataProtocolloOrdine !=null ||
					vm.ordine.identificativoContratto !=null ))
    			return true;

			return false;
        }

        // inca$ - gestione campi informazioni amministrazione
        function disableInformazioniAmministrazione () {
        	//return false;
        	
			if (vm.ordine.stato == null)
				return true;

			if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_SUPERUTENTE']) &&
					vm.ordine.stato == 'VALIDATO'))
    			return false;

			return true;
        }

        // inca$ - gestione campo protocollo
        function showProtocollo () {

			if (vm.ordine.stato == null)
				return false;
			
			if (vm.ordine.documentoPdf==null)
				return false;
			
			if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_SUPERUTENTE']) ||
					vm.ordine.documentoPdf.numeroProtocollo !=null))
    			return true;

			return false;
        }

        // inca$ - gestione campo protocollo
        function disableProtocollo () {

			if (vm.ordine.stato == null)
				return true;

			if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_SUPERUTENTE']) &&
					vm.ordine.stato == 'VALIDATO'))
    			return false;

			return true;
        }

        // inca$ - aggiunta gestione rifiuto
        function rejectDialogForm() {
        	$uibModal.open({
                templateUrl: 'app/util/entity-reject.html',
                controller: 'EntityRejectController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    controller: function() {
                        return vm;
                    },
                    entityName: function() {
                        return 'ordine';
                    }
                }
            }).result.then(function() {
            	vm.reject();
            }, function() {
            });
        }

        // inca$ - aggiunta gestione rifiuto
        function sendDialogForm() {
        	$uibModal.open({
                templateUrl: 'app/util/send/multiple-send.html',
                controller: 'MultipleSendController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    controller: function() {
                        return vm;
                    },
                    entityName: function() {
                        return 'ordine';
                    },
                    entity: function() {
                        return vm.ordine;
                    }
                }
            }).result.then(function(result) {
            	if (result) 
            		vm.save2();
                vm.save3();

            }, function() {
                vm.invia = false;
            });
        }

        function eraseHiddenFields () {
        }
        
        function findDettagliOrdine () {
        	if (vm.ordine.id!=null) 
        		vm.dettagliOrdine = DettaglioOrdineDBSearch.query({size: 100000000, query: {ordineId: vm.ordine.id}}, dettagliOnSuccess);
            
        	function dettagliOnSuccess(data, headers) {
                vm.calcTotaliDattaglio();
            }

        }

        function addDettaglioOrdine () {
        	
        	//findDettagliOrdine ();
        	
        	$uibModal.open({
              templateUrl: 'app/entities/dettaglio-ordine/dettaglio-ordine-dialog.html',
              controller: 'DettaglioOrdineDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('dettaglioOrdine');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
						return {
                            quantita: null,
                            descrizioneArticolo: null,
                            codiceArticolo: null,
                            importoUnitario: null,
                            id: null,
                            ordine: vm.ordine
						}
                    },
                    controller: function() {
                        return vm;
                    },
                    entityName: function() {
                        return 'dettaglioOrdine';
                    },
                    ordineItems: function() {
                        return vm.ordine;
                    } 
                }
            }).result.then(function() {
            	vm.findDettagliOrdine();
            }, function() {
            });
        }

        function editDettaglioOrdine (dettaglioOrdine) {
        	
        	findDettagliOrdine ();
        	
        	$uibModal.open({
              templateUrl: 'app/entities/dettaglio-ordine/dettaglio-ordine-dialog.html',
              controller: 'DettaglioOrdineDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('dettaglioOrdine');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: dettaglioOrdine,
                    controller: function() {
                        return vm;
                    },
                    entityName: function() {
                        return 'dettaglioOrdine';
                    },
                    ordineItems: function() {
                        return vm.ordine;
                    }
                }
            }).result.then(function() {
            	vm.findDettagliOrdine();
            }, function() {
            });
        }

        function deleteDettaglioOrdine (dettaglioOrdine) {
            
        	findDettagliOrdine ();
        	
        	$uibModal.open({
                templateUrl: 'app/entities/dettaglio-ordine/dettaglio-ordine-delete-dialog.html',
                controller: 'DettaglioOrdineDeleteController',                       
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('dettaglioOrdine');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return dettaglioOrdine;
                    }
                }
            }).result.then(function() {
            	vm.findDettagliOrdine();
            }, function() {
            });

        };
        
        function calcTotaliDattaglio() {
            vm.totaleImponibile=0;
            vm.totaleIva=0;
            vm.totaleOrdine=0;
        	for (var i = 0, len = vm.dettagliOrdine.length; i < len; i++) {
        		var dettaglio=vm.dettagliOrdine[i];
                vm.totaleImponibile=Number(vm.totaleImponibile.toFixed(vm.numeroDecimaliImporto))+(dettaglio.importoUnitario*dettaglio.quantita);
                vm.totaleIva=Number(vm.totaleIva.toFixed(vm.numeroDecimaliImporto))+(dettaglio.importoUnitario*dettaglio.quantita*dettaglio.percentualeIva/100);
            }
        	vm.totaleImponibile=Number(vm.totaleImponibile.toFixed(vm.numeroDecimaliImporto));
        	vm.totaleIva=Number(vm.totaleIva.toFixed(vm.numeroDecimaliImporto));
            vm.totaleOrdine=vm.totaleImponibile+vm.totaleIva;
        }
        
        function percentualeIvaInit() {
        	if (vm.ordine.id==null)
        		return vm.ordine.percentualeIva=22;
        }

        function viewMailSigns () {
    		
        	if (vm.ordine.stato == null)
    			return false;

    		if (Permissions.hasOneOfRoles(['ROLE_SUPERUTENTE']) && vm.ordine.stato == 'VALIDATO')
    			return true;
    		
    		return false;
        }

        function sendMailSigns () {
        	MailSigns.sendMail(vm, vm.ordine.id);
        }
    
        function mailSent (result) {
        	$uibModalInstance.dismiss('cancel');
        }

        function viewUpdateStato () {
    		
        	if (vm.ordine.stato == null)
    			return false;

    		if (Permissions.hasOneOfRoles(['ROLE_SUPERUTENTE'])) 
    			return true;
    		
    		return false;
        }

        function updateStato () {
        	AdminCommands.updateStatoOrdine(vm, vm.ordine.id, vm.statoForUpdate);
        }

        function updateStatoDone (result) {
        	$uibModalInstance.dismiss('cancel');
        }

        function currencySymbol () {
        	if (vm.ordine.valuta==null)
        		return "€";
        	
        	var symbol = currencySymbols[vm.ordine.valuta];
        	
        	if (symbol!=null)
                return symbol;
        	else
                return vm.ordine.valuta;
               
        }
        
        function dichiarazioneChanged () {
        	if (vm.ordine.dichiarazione!=null) {
        		vm.ordine.luogoConsegna=vm.ordine.dichiarazione.decisione.luogoConsegna;
        	
        		Decisione.get({id : vm.ordine.dichiarazione.decisione.id}, function(result) {
         			vm.ordine.dichiarazione.decisione=result;
                });

        	}
        
        	vm.refreshDecisioneEsteso();
        }

        function editDichiarazione () {
        	
        	if (vm.ordine!=null && vm.ordine.dichiarazione!=null) {
            	$uibModal.open({
                    templateUrl: 'app/entities/dichiarazione/dichiarazione-dialog.html',
                    controller: 'DichiarazioneDialogController',
                      controllerAs: 'vm',
                      backdrop: 'static',
                      size: 'md',
                      resolve: {
                          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                              $translatePartialLoader.addPart('dichiarazione');
                              $translatePartialLoader.addPart('decisione');
                              $translatePartialLoader.addPart('statoDichiarazione');
                              $translatePartialLoader.addPart('tipoEsclusivita');
                              $translatePartialLoader.addPart('documento'); // inca$ - aggiungo la traduzione di documento
                              $translatePartialLoader.addPart('global');
                              return $translate.refresh();
                          }],
    	                  entity: ['Dichiarazione', function(Dichiarazione) {
    	                	  return Dichiarazione.get({id : vm.ordine.dichiarazione.id}).$promise;
	                      }],
                          controller: function() {
                              return vm;
                          },
                          entityName: function() {
                              return 'dichiarazione';
                          },
                      }
                }).result.then(function(dichiarazione) {
                	vm.ordine.dichiarazione=dichiarazione;
                	
                    // ricarichiamo il valore nella select list
                	$q.all([vm.ordine.$promise, vm.dichiaraziones.$promise]).then(function() {
                        if (!dichiarazione || !dichiarazione.id) {
                            return $q.reject();
                        }
                        return Dichiarazione.get({id : dichiarazione.id}).$promise;
                    }).then(function(dichiarazioneLoaded) {
    	            	for (var i=0;i<vm.dichiaraziones.length;i++){
    	   	        		if (vm.dichiaraziones[i].id==dichiarazione.id) {
    	   	        			vm.dichiaraziones.splice(i,1);
    	   	        		}
    	            	}
                        vm.dichiaraziones.push(dichiarazioneLoaded);
    	        		if (dichiarazioneLoaded.id=dichiarazione.id) {
		        			vm.ordine.dichiarazione=dichiarazioneLoaded;
		        		}
                    });
                	
        		    // ricarichiamo il file dato che potrebbe essere stato rigenerato
                	if (entity.documentoPdf!=null)
        	    		DocumentoFile.get({id : entity.documentoPdf.id}, function(result) {
        	     			vm.ordine.documentoPdf["file"]=result.file;
        	            });


                }, function() {
                });
        	}
        }

        function editDecisione () {
        	
        	if (vm.ordine!=null && vm.ordine.dichiarazione!=null) {
        		
	        	$uibModal.open({
	              templateUrl: 'app/entities/decisione/decisione-dialog.html',
	              controller: 'DecisioneDialogController',
	                controllerAs: 'vm',
	                backdrop: 'static',
	                size: 'md',
	                resolve: {
	                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                        $translatePartialLoader.addPart('decisione');
	                        $translatePartialLoader.addPart('tipoOrdine');
	                        $translatePartialLoader.addPart('tipoDecisione');
	                        $translatePartialLoader.addPart('statoDecisione');
	                        $translatePartialLoader.addPart('documento'); // inca$ - aggiungo la traduzione di documento
	                        $translatePartialLoader.addPart('global');
	                        return $translate.refresh();
	                    }],
	                    entity: ['Decisione', function(Decisione) {
	                        return Decisione.get({id : vm.ordine.dichiarazione.decisione.id}).$promise;
	                    }],
	                    controller: function() {
	                        return vm;
	                    },
	                    entityName: function() {
	                        return 'decisione';
	                    },
	                }
	            }).result.then(function(decisione) {
	            	vm.ordine.dichiarazione.decisione=decisione;
	            	
	            	vm.refreshDecisioneEsteso();

        		    // ricarichiamo il file dato che potrebbe essere stato rigenerato
//	            	if (entity.documentoPdf!=null)
//	    	    		DocumentoFile.get({id : entity.documentoPdf.id}, function(result) {
//	    	     			vm.ordine.documentoPdf["file"]=result.file;
//	    	            });
	            	
	            }, function() {
	            });
        	}
        }

        function docFileName (ordine, fileContentType) {
        	var extn = null;
        	if (ordine.documentoPdf.fileContentType!=null)
        		extn = ordine.documentoPdf.fileContentType.split("/").pop();
        	else
        		extn = fileContentType.split("/").pop();
        	return findAndReplace(ordine.dichiarazione.decisione.impegno.codice,"/","_").replace(/[|&;$%@"<>()+,]/g, "")+"_ordine_ID_"+ordine.id+"."+extn;
        }

        function findAndReplace(string, target, replacement) {
        	 var i = 0, length = string.length;
        	 for (i; i < length; i++) {
        	   string = string.replace(target, replacement);
        	 }
        	 return string;
        }

        function disablePercentualeIva () {

        	if (vm.dettagliOrdine != null && vm.dettagliOrdine.length>0) {
        		return true;
        	}
        	return false;

        }

        function printIvaOnDetails () {

        	if (vm.dettagliOrdine != null && vm.dettagliOrdine.length>0) {
        		
            	for (var i = 0, len = vm.dettagliOrdine.length; i < len; i++) {
            		var dettaglio=vm.dettagliOrdine[i];
            		if (dettaglio.percentualeIva!=vm.ordine.percentualeIva)
            			return true;
                }
        	}
        	return false;

        }
    }

})();
