(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('OperazioneDetailController', OperazioneDetailController);

    OperazioneDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Operazione', 'RuoloFunzione'];

    function OperazioneDetailController($scope, $rootScope, $stateParams, previousState, entity, Operazione, RuoloFunzione) {
        var vm = this;

        vm.operazione = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('ordiniApp:operazioneUpdate', function(event, result) {
            vm.operazione = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
