(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .factory('MailSigns', MailSigns);

    MailSigns.$inject = ['$http'];

    function MailSigns ($http) {
        var service = {
        	sendMail: sendMail,
        	sendMailMepa: sendMailMepa
        };

        return service;
        
        function sendMail (vmp, id) {
            return $http({
        			url: 'api/mail-signs/', 
        			method: 'POST',
        			params: {id: id}
            	}).then(getResponse, onError);

            function getResponse (response) {
            	vmp.mailSent(response);
            	return response;
            }

            function onError (response) {
            	return response;
            }
        }

        function sendMailMepa (vmp, id) {
            return $http({
        			url: 'api/mail-signs-mepa/', 
        			method: 'POST',
        			params: {id: id}
            	}).then(getResponse, onError);

            function getResponse (response) {
            	vmp.mailSent(response);
            	return response;
            }

            function onError (response) {
            	return response;
            }
        }
    }
})();
