(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('SearchController',SearchController);

    SearchController.$inject = ['$uibModalInstance', 'miSearchService', '$filter', '$scope', 'pScope', 'search', 'controller', 'field', 'type', 'domain'];

    
    function SearchController($uibModalInstance, miSearchService, $filter, $scope, pScope, search, controller, field, type, domain) {
        var vm = this;

        vm.pScope=pScope;
        vm.search = search;
        vm.controller = controller;
        vm.field = field;
        vm.type = type;
        vm.domain=domain;

        vm.clear = clear;
        vm.confirmSearch = confirmSearch;
        vm.addCondition = addCondition;
        vm.dateformat='dd/MM/yyyy';
        vm.datetimeformat='dd/MM/yyyy HH:mm:ss';
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.dataIniziale = false;
        vm.datePickerOpenStatus.dataFinale = false;
        vm.datePickerOpenStatus.dataOraIniziale = false;
        vm.datePickerOpenStatus.dataOraFinale = false;
        vm.isDomain = isDomain;
        vm.isDate = isDate;
        vm.isDatetime = isDatetime;
        vm.isBoolean = isBoolean;
        vm.isText = isText;
        vm.isFirstCondition = isFirstCondition;
        vm.isOnlyOneCondition = isOnlyOneCondition;
        vm.availableOptions;
        vm.updateDomain=updateDomain;
        
        var init = function () {
            vm.condition="AND";

            if (isBoolean()) {
	            vm.relations = {
		    		model: null,
		    	    availableOptions: [
						{relation: 'UGUALE', name: 'uguale a'}
					]
	            };
	            vm.relations.model="UGUALE";
            } else if (isDate() || isDatetime()) {
	            vm.relations = {
		    		model: null,
		    	    availableOptions: [
						{id: 'UGUALE', name: 'uguale a'},
						{id: 'DIVERSO', name: 'diverso da'},
						{id: 'MAGGIORE', name: 'maggiore di'},
						{id: 'MAGGIOREUG', name: 'maggiore o uguale a'},
						{id: 'MINORE', name: 'minore di'},
						{id: 'MINOREEUG', name: 'minore o uguale a'},
						{id: 'COMPRESO', name: 'compreso tra i valori'},
						{id: 'NONCOMPRESO', name: 'non compreso tra i valori'}
					]
	            };
	            vm.relations.model="UGUALE";
            } else if (isDomain()) {
	            vm.relations = {
		    		model: null,
		    	    availableOptions: [
						{id: 'UGUALE', name: 'uguale a'},
						{id: 'DIVERSO', name: 'diverso da'},
					]
	            };
	            vm.relations.model="UGUALE";
            } else {
	            vm.relations = {
    	    		model: null,
    	    	    availableOptions: [
    					{id: 'UGUALE', name: 'uguale a'},
    					{id: 'DIVERSO', name: 'diverso da'},
    					{id: 'CONTIENE', name: 'contiene'},
    					{id: 'NONCONTIENE', name: 'non contiene'},
    					{id: 'MAGGIORE', name: 'maggiore di'},
    					{id: 'MAGGIOREUG', name: 'maggiore o uguale a'},
    					{id: 'MINORE', name: 'minore di'},
    					{id: 'MINOREEUG', name: 'minore o uguale a'},
    					{id: 'COMPRESO', name: 'compreso tra i valori'},
    					{id: 'NONCOMPRESO', name: 'non compreso tra i valori'}
    				]
                };
                vm.relations.model="CONTIENE";
            }

            if (vm.domain!=null) {
	            miSearchService.getDomain(vm).then(function(domain) {
	            });
            }
        }
        init();
        

        function updateDomain (data) {
        	
            vm.domains = {
	    		model: null,
	    	    availableOptions: []
            };

            var values = getDescendantProp(data, vm.domain);
            //var entries = Object.entries(values); // metodo incompatibile con Safari e altri
            var entries = new Array;
            
            var ent=0;
            Object.keys(values).forEach(function (prop) {
            	var value = values[prop];
            	entries[ent]=[prop, value];
            	ent++;
           	});

			for (var i = 0; i < entries.length; i++) {
				if (entries[i][0]!=null && entries[i][1]!="")
					vm.domains.availableOptions.push({
				        id: entries[i][0],
				        name: entries[i][1]
				    });
			}
            
        }

        function isDomain () {
        	return !(vm.domain==null||vm.domain=="");
        }

        function isDate () {
            return vm.type=="date";
        }

        function isDatetime () {
            return vm.type=="datetime";
        }

        function isBoolean () {
            return vm.type=="boolean";
        }

        function isFirstCondition () {
            var query = vm.pScope.vm.searchQuery;
        	if (query==null || query=="")
        		return true;
        	return false;
        }
    	
        function isOnlyOneCondition () {
        	return vm.relations.model!='COMPRESO'&&vm.relations.model!='NONCOMPRESO'
        }
        
    	function isText () {
            return (vm.type==null||vm.type=="") && !isDomain();
        }
   
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function addCondition () {
        	
        	var relazione = "";
        	var valIni = null;
        	var valFin = null;
        	
        	if (isDate()) {
        		valIni = $filter('date')(vm.dataIniziale, "yyyy-MM-dd");
        		valFin = $filter('date')(vm.dataFinale, "yyyy-MM-dd");

        		if (valIni==null || valIni=="")
        			valIni = "*";
        		if (valFin==null || valFin=="")
        			valFin = "*";
        	
        	} else if (isDatetime()) {
        		valIni = $filter('date')(vm.dataOraIniziale, "yyyy-MM-ddTHH:mm:ss");
        		valFin = $filter('date')(vm.dataOraFinale, "yyyy-MM-ddTHH:mm:ss");
        		
        		if (valIni==null || valIni=="")
        			valIni = "*";
        		if (valFin==null || valFin=="")
        			valFin = "*";

        	} else {
            	if (vm.valoreIniziale==null)
            		valIni=false;
            	else { 
            		valIni = vm.valoreIniziale;
            		valFin = vm.valoreFinale;
            	}		
        	}

    		if (vm.relations.model=="UGUALE") {
    			if (isDomain()) {
    				var arrayLength = 0;
					if (vm.domains.model!=null)
						arrayLength=vm.domains.model.length;
    				if (arrayLength>0) {
    					if (arrayLength>1)
    						relazione = "(";
	    				for (var i = 0; i < arrayLength; i++) {
	        				relazione = relazione+vm.field+":"+vm.domains.model[i];
	        				if (i<arrayLength-1)
	        					relazione = relazione+" OR ";
	    				}
    					if (arrayLength>1)
    						relazione = relazione+")";
    				}
    			} else
    				relazione = vm.field+":"+"\""+valIni+"\"";
        	} else if (vm.relations.model=="DIVERSO") {
    			if (isDomain()) {
    				var arrayLength = 0;
					if (vm.domains.model!=null)
						arrayLength=vm.domains.model.length;
    				if (arrayLength>0) {
        				relazione = "NOT ";
    					if (arrayLength>1)
            				relazione = relazione+"(";
	    				for (var i = 0; i < arrayLength; i++) {
	        				relazione = relazione+vm.field+":"+vm.domains.model[i];
	        				if (i<arrayLength-1)
	        					relazione = relazione+" OR ";
	    				}
    					if (arrayLength>1)
    						relazione = relazione+")";
    				}
    			} else
    				relazione = "NOT "+vm.field+":"+"\""+valIni+"\"";
        	} else if (vm.relations.model=="CONTIENE") {
   				relazione = vm.field+":\"*"+valIni+"*\"";
        	} else if (vm.relations.model=="NONCONTIENE") {
   				relazione = "NOT "+vm.field+":\"*"+valIni+"*\"";
        	} else if (vm.relations.model=="MAGGIORE") {
        		if (isDate()||isDatetime()) {
        			relazione = vm.field+":["+valIni+" TO *]";
        		} else
        			relazione = vm.field+":>"+"\""+valIni+"\"";
        	} else if (vm.relations.model=="MAGGIOREUG") {
        		if (isDate()||isDatetime()) {
        			relazione = vm.field+":["+valIni+" TO *]";
        		} else
        			relazione = vm.field+":>="+valIni;
        	} else if (vm.relations.model=="MINORE") {
        		if (isDate()||isDatetime()) {
        			relazione = vm.field+":[* TO "+valIni+"]";
        		} else
        			relazione = vm.field+":<"+valIni;
        	} else if (vm.relations.model=="MINOREEUG") {
        		if (isDate()||isDatetime()) {
        			relazione = vm.field+":[* TO "+valIni+"]";
        		} else
        			relazione = vm.field+":<="+valIni;
        	} else if (vm.relations.model=="COMPRESO") {
        		relazione = vm.field+":["+"\""+valIni+"\""+" TO "+"\""+valFin+"\""+"]";
        	} else if (vm.relations.model=="NONCOMPRESO") {
        		relazione = vm.field+":<"+valIni+" OR "+vm.field+":>"+valFin;
        	}
        	
            var query = vm.pScope.vm.searchQuery;
        	if (query==null || query=="")
        		vm.pScope.vm.searchQuery = relazione;
        	else
        		vm.pScope.vm.searchQuery = query+" "+vm.condition+" "+relazione;

            vm.search.searchQuery=vm.pScope.vm.searchQuery;
            
//            $("#searchQuery")
//              .val(search.searchQuery)
//              .trigger('change');

//            $uibModalInstance.dismiss('cancel');
            $uibModalInstance.close(true);
            
        }

        function confirmSearch () {
        	addCondition();
        	vm.controller.search(search.searchQuery);
        }

        function getDescendantProp(obj, desc) {
            var arr = desc.split(".");
            while(arr.length && (obj = obj[arr.shift()]));
            return obj;
        }
        
}
})();
