(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .factory('ImpegnoDBSearch', ImpegnoDBSearch);

    ImpegnoDBSearch.$inject = ['$resource'];

    function ImpegnoDBSearch($resource) {
        var resourceUrl =  'api/_dbsearch/impegnos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
