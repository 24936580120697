(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('DecisioneImpegnoDeleteController',DecisioneImpegnoDeleteController);

    DecisioneImpegnoDeleteController.$inject = ['$uibModalInstance', 'entity', 'Impegno'];

    function DecisioneImpegnoDeleteController($uibModalInstance, entity, Impegno) {
        var vm = this;

        vm.impegno = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (impegno) {
        	impegno.decisione=null;
            Impegno.update(impegno,
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
