(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('ProgettoDialogController', ProgettoDialogController);

    ProgettoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Progetto', 'Finanziamento', 'Utente'];

    function ProgettoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Progetto, Finanziamento, Utente) {
        var vm = this;

        vm.progetto = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.finanziamentos = Finanziamento.query({size:100000000}); // inca$ - caricamento totale liste fk
        vm.utentes = Utente.query({size:100000000}); // inca$ - caricamento totale liste fk

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.progetto.id !== null) {
                Progetto.update(vm.progetto, onSaveSuccess, onSaveError);
            } else {
                Progetto.save(vm.progetto, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ordiniApp:progettoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataCreazione = false;
        vm.datePickerOpenStatus.dataAggiornamento = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
