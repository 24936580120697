(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('dichiarazione', {
            parent: 'entity',
            url: '/dichiarazione?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ordiniApp.dichiarazione.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/dichiarazione/dichiaraziones.html',
                    controller: 'DichiarazioneController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc', // inca$ - default order
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('dichiarazione');
                    $translatePartialLoader.addPart('decisione'); // inca$ - aggiungo la traduzione di decisione
                    $translatePartialLoader.addPart('statoDichiarazione');
                    $translatePartialLoader.addPart('tipoEsclusivita');
                    $translatePartialLoader.addPart('documento'); // inca$ - aggiungo la traduzione di documento
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('dichiarazione-detail', {
            parent: 'entity',
            url: '/dichiarazione/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ordiniApp.dichiarazione.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/dichiarazione/dichiarazione-detail.html',
                    controller: 'DichiarazioneDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('dichiarazione');
                    $translatePartialLoader.addPart('statoDichiarazione');
                    $translatePartialLoader.addPart('tipoEsclusivita');
                    $translatePartialLoader.addPart('documento'); // inca$ - aggiungo la traduzione di documento
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Dichiarazione', function($stateParams, Dichiarazione) {
                    return Dichiarazione.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'dichiarazione',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('dichiarazione-detail.edit', {
            parent: 'dichiarazione-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dichiarazione/dichiarazione-dialog.html',
                    controller: 'DichiarazioneDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Dichiarazione', function(Dichiarazione) {
                            return Dichiarazione.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dichiarazione.new', {
            parent: 'dichiarazione',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dichiarazione/dichiarazione-dialog.html',
                    controller: 'DichiarazioneDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                data: null,
                                stato: null,
                                nessunaComparazione: false,
                                descrizione: null,
                                ordineMepa: false,
                                strumentoMepaScelto: null,
                                sezioneMepa: null,
                                criteriRicercaArticoli: null,
                                numeroOfferteMepa: null,
                                fornitoreScelto: null,
                                motivazioneSceltaProdotto: null,
                                motivazioneSceltaFornitore: null,
                                spesaEffettiva: null,
                                valuta: null,
                                beneInventariabile: false,
                                edificio: null,
                                indirizzo: null,
                                numeroStanza: null,
                                nomeAssegnatarioBene: null,
                                territorio: null,
                                motivazioneRifiuto: null,
                                utenteCreazione: null,
                                dataCreazione: null,
                                utenteAggiornamento: null,
                                dataAggiornamento: null,
                                versioneRecord: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('dichiarazione', null, { reload: 'dichiarazione' });
                }, function() {
                    $state.go('dichiarazione');
                });
            }]
        })
        .state('dichiarazione.edit', {
            parent: 'dichiarazione',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dichiarazione/dichiarazione-dialog.html',
                    controller: 'DichiarazioneDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Dichiarazione', function(Dichiarazione) {
                            return Dichiarazione.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dichiarazione', null, { reload: 'dichiarazione' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dichiarazione.delete', {
            parent: 'dichiarazione',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dichiarazione/dichiarazione-delete-dialog.html',
                    controller: 'DichiarazioneDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Dichiarazione', function(Dichiarazione) {
                            return Dichiarazione.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dichiarazione', null, { reload: 'dichiarazione' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
