(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .factory('Permissions', Permissions);

    Permissions.$inject = ['$http', 'Principal'];

    function Permissions ($http, Principal) {
        var service = {
           	getAccount: getAccount,
        	loadPermissionTree: loadPermissionTree,
        	getPermissionTree: getPermissionTree,
        	hasRole: hasRole,
        	hasOneOfRoles: hasOneOfRoles,
        	loadRoles: loadRoles,
        	hasPermission: hasPermission
        };

        var vm = this;
        vm.account=null;
        vm.permissionTree=null;
        vm.roles=null;
        
        loadPermissionTree();
        loadRoles();

        return service;
        
        function getAccount() {
        	if (vm.account==null)
        		loadRoles();
        	return vm.account;
        }

        function loadPermissionTree () {
            return $http.get('api/util/permission-tree').then(getTree);

            function getTree (response) {
            	vm.permissionTree=response.data
            	return response;
            }
        }

        function getPermissionTree () {
        	if (vm.permissionTree==null)
        		loadPermissionTree();
        }

        function hasRole (role) {
        	if (vm.roles!=null) {
            	var i, len;
            	for (i = 0, len = vm.roles.length; i < len; i++) {
            		if (vm.roles[i]===role)
            			return true;
            	}
        	}
        	return false;
        }

        function hasOneOfRoles (roles) {
        	if (vm.roles!=null) {
	        	var i, len;
	        	for (i = 0, len = vm.roles.length; i < len; i++) {
	            	var j, len2, text;        	
	            	for (j = 0, len2 = roles.length; j < len2; j++) {
	            		if (vm.roles[i]===roles[j])
	            			return true;
	            	}
	        	}
        	}
        	return false;
        }

        function loadRoles () {
            
        	Principal.identity().then(function(account) {
                vm.account = account;
                if (account!=null)
                	vm.roles = account.authorities;
            });

        }

// non più usato, ora uso il service Principal        
//        function loadRoles () {
//            return $http.get('/api/util/roles').then(getRoles);
//        		
//            function getRoles (response) {
//            	vm.roles=response.data
//            	return response;
//            }
//        }

        function hasPermission(func, operation) {

        	if (vm.permissionTree!=null) {
	        	var roles = vm.permissionTree.roleFunctions;
				for (var i = 0; i < vm.roles.length; i++) {
		        	var functions = vm.permissionTree.roleFunctions[vm.roles[i]];
		        	if (functions!=null) {
			        	var operations = functions[func];
			        	if (operations!=null) {
							for (var j = 0; j < operations.length; j++) {
					        	if (operations[j]===operation)
					        		return true;
							}
						}
		        	}
				}
        	}
        	return false;
        }

    }
})();
