(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .factory('ordineItems', ordineItems);

    ordineItems.$inject = ['$http'];

    function ordineItems ($http) {
        var service = {
        };

        var vm = this;
        vm.id=null;

        return service;
    }
})();
