(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('ImpegnoDialogController', ImpegnoDialogController);

    ImpegnoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Impegno', 'Decisione', 'Gae', 'Struttura', 'VoceBilancio', 'Utente'];

    function ImpegnoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Impegno, Decisione, Gae, Struttura, VoceBilancio, Utente) {
        var vm = this;

        vm.impegno = entity;
        vm.impegnoEsteso = null;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.decisiones = Decisione.query();
        //vm.gaes = Gae.query({size:100000000, viewableOnly: true}); // inca$ - caricamento totale liste fk
        vm.gaes = Gae.query({size:100000000}); // inca$ - caricamento totale liste fk
        vm.strutturas = Struttura.query();
    	vm.esercizioVoce = esercizioVoce;
        vm.vocebilancios = VoceBilancio.query({size:100000000, filterOn: true, viewableOnly: true, esercizio: vm.esercizioVoce()}); // inca$ - caricamento totale liste fk
        vm.utentes = Utente.query({size:100000000}); // inca$ - caricamento totale liste fk

        {
        	if (vm.impegno.decisione!=null)
        		vm.impegnoEsteso = "ID: "+vm.impegno.decisione.id+' - '+vm.impegno.decisione.impegno.codice+' - '+vm.impegno.decisione.stato;
        	else
        		vm.impegnoEsteso = null;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.impegno.id !== null) {
                Impegno.update(vm.impegno, onSaveSuccess, onSaveError);
            } else {
                Impegno.save(vm.impegno, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ordiniApp:impegnoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataCreazione = false;
        vm.datePickerOpenStatus.dataAggiornamento = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

    
        function esercizioVoce () {
        	if (vm.impegno!=null && vm.impegno.voceBilancio!=null)
        		return vm.impegno.voceBilancio.esercizio;
        	if (vm.impegno!=null && vm.impegno.esercizio!=null)
        		return vm.impegno.esercizio;
        	return (new Date()).getFullYear();
        }
        
    }
})();
