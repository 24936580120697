(function () {
    'use strict';

    angular
        .module('ordiniApp')
        .config(pagerConfig);

    // inca$ - aggiunto per evitare l'errore 
    // Error: [$injector:strictdi] pagerConfig is not using explicit annotation and cannot be invoked in strict mode
    pagerConfig.$inject = ['uibPagerConfig', 'paginationConstants'];

    function pagerConfig(uibPagerConfig, paginationConstants) {
        uibPagerConfig.itemsPerPage = paginationConstants.itemsPerPage;
        uibPagerConfig.previousText = '«';
        uibPagerConfig.nextText = '»';
    }
})();
