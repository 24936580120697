(function() {
    //'use strict';

    angular
        .module('ordiniApp')
        .controller('MultipleSendController',MultipleSendController);

    MultipleSendController.$inject = ['$uibModalInstance', '$filter', 'controller', 'entityName', 'entity', 'Decisione', 'Dichiarazione', 'Ordine', 'Permissions'];

    function MultipleSendController($uibModalInstance, $filter, controller, entityName, entity, Decisione, Dichiarazione, Ordine, Permissions) {
        var vm = this;

    	vm.utenteLogin = Permissions.getAccount().login;
        vm.clear = clear;
        vm.confirm = confirm;
        vm.controller = controller;
        vm.entityName = entityName;
        vm.error = null;
        vm.decisione = null;
        vm.dichiarazione = null;
        vm.ordine = null;
        vm.cbDecisione = true;
        vm.cbDichiarazione = true;
        vm.cbOrdine = true;
        vm.decisioneOK = true;
        vm.dichiarazioneOK = true;
        vm.ordineOK = true;
        vm.statoValido = statoValido;
        vm.addMessage=addMessage;
        vm.inviaEnabled = true;
        vm.errorRows = 0;
        
        {
			if (vm.entityName=='ordine') {
				vm.ordine = entity;
				
	        	Dichiarazione.get({id : vm.ordine.dichiarazione.id}, function(result) {
		 			vm.dichiarazione=result;
		        });

	        	Decisione.get({id : vm.ordine.dichiarazione.decisione.id}, function(result) {
		 			vm.decisione=result;
		        });

			} else if (vm.entityName=='dichiarazione') {
				vm.dichiarazione = entity;
					
	        	Decisione.get({id : vm.dichiarazione.decisione.id}, function(result) {
		 			vm.decisione=result;
		        });
			}
        }
 
        function statoValido (stato) {
        	if (stato=='IN_COMPILAZIONE'||
        			stato=='RIFIUTATO_DA_RESPONSABILE'||
        			stato=='RIFIUTATO_DA_AMMINISTRAZIONE'||
        			stato=='RIFIUTATO_DA_DIRETTORE')
        		return true;
        	else
        		return false;
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function decisioneUpdate () {
    		vm.decisione.stato="INVIATO";
    		vm.decisioneOK=false;
    		Decisione.update(vm.decisione, onDecisioneSaveSuccess, onDecisioneSaveError);
        }

        function dichiarazioneUpdate () {
			if (vm.dichiarazione.decisione.impegno.gae.finanziamento.responsabileFondi.direttore == true ||
				vm.dichiarazione.decisione.impegno.gae.finanziamento.responsabileFondi.user.login == vm.utenteLogin)
				vm.dichiarazione.stato='INVIATO_A_AMMINISTRAZIONE';
			else
				vm.dichiarazione.stato='INVIATO';
    		vm.dichiarazioneOK=false;
			Dichiarazione.update(vm.dichiarazione, onDichiarazioneSaveSuccess, onDichiarazioneSaveError);
        }

        function ordineUpdate () {
			if (vm.ordine.dichiarazione.decisione.impegno.gae.finanziamento.responsabileFondi.direttore == true ||
				vm.ordine.dichiarazione.decisione.impegno.gae.finanziamento.responsabileFondi.user.login == vm.utenteLogin)
				vm.ordine.stato='INVIATO_A_AMMINISTRAZIONE';
			else
				vm.ordine.stato='INVIATO';
    		vm.ordineOK=false;
			Ordine.update(vm.ordine, onOrdineSaveSuccess, onOrdineSaveError);
        }

        function confirm (id) {
        	if (vm.decisione!=null&&vm.cbDecisione&&vm.entityName!='decisione'&&vm.statoValido(vm.decisione.stato)) {
        		decisioneUpdate();
        	}
        	if (vm.dichiarazione!=null&&vm.cbDichiarazione&&vm.entityName!='dichiarazione'&&vm.statoValido(vm.dichiarazione.stato)) {
        		dichiarazioneUpdate();
        	}
        	if (vm.ordine!=null&&vm.cbOrdine&&vm.entityName!='ordine'&&vm.statoValido(vm.ordine.stato)) {
        		ordineUpdate();
        	}

        	var send=false;
        	if ((vm.cbDecisione&&vm.entityName=='decisione')||
        			(vm.cbDichiarazione&&vm.entityName=='dichiarazione')||
        			(vm.cbOrdine&&vm.entityName=='ordine'))
        		send=true;
        	
        	if (vm.decisioneOK&&vm.dichiarazioneOK&&vm.ordineOK)
	        	$uibModalInstance.close(send);
        }
        
        function onDecisioneSaveSuccess (result) {
        	vm.decisioneOK=true;
			vm.dichiarazione=result;
			
        	var send=false;
        	if ((vm.cbDecisione&&vm.entityName=='decisione')||
        			(vm.cbDichiarazione&&vm.entityName=='dichiarazione')||
        			(vm.cbOrdine&&vm.entityName=='ordine'))
        		send=true;

        	vm.addMessage("La Decisione di contrattare è stata inviata correttamente");

        	if (vm.decisioneOK&&vm.dichiarazioneOK&&vm.ordineOK)
	        	$uibModalInstance.close(send);
        }

        function onDecisioneSaveError () {
    		// rimetto in compilazione per riabilitare il checkbox
    		vm.decisione.stato="IN_COMPILAZIONE";
    		vm.addMessage("Non è stato possibile inviare la Decisione di contrattare, correggere l'errore segnalato e inviarlo nuovamente.");
            vm.inviaEnabled = false;
        }

        function onDichiarazioneSaveSuccess (result) {
        	vm.dichiarazioneOK=true;
			vm.dichiarazione=result;

        	var send=false;
        	if ((vm.cbDecisione&&vm.entityName=='decisione')||
        			(vm.cbDichiarazione&&vm.entityName=='dichiarazione')||
        			(vm.cbOrdine&&vm.entityName=='ordine'))
        		send=true;

        	vm.addMessage("La Dichiarazione RUP è stata inviata correttamente");

        	if (vm.decisioneOK&&vm.dichiarazioneOK&&vm.ordineOK)
	        	$uibModalInstance.close(send);
        }

        function onDichiarazioneSaveError () {
    		// rimetto in compilazione per riabilitare il checkbox
    		vm.dichiarazione.stato="IN_COMPILAZIONE";
        	vm.addMessage("Non è stato possibile inviare la Dichiarazione RUP, correggere l'errore segnalato e inviarlo nuovamente.");
            vm.inviaEnabled = false;
        }

        function onOrdineSaveSuccess (result) {
        	vm.ordineOK=true;
			vm.ordine=result;

        	var send=false;
        	if ((vm.cbDecisione&&vm.entityName=='decisione')||
        			(vm.cbDichiarazione&&vm.entityName=='dichiarazione')||
        			(vm.cbOrdine&&vm.entityName=='ordine'))
        		send=true;

        	vm.addMessage("L'Ordine è stato inviato correttamente");

        	if (vm.decisioneOK&&vm.dichiarazioneOK&&vm.ordineOK)
	        	$uibModalInstance.close(send);
        }

        function onOrdineSaveError () {
    		// rimetto in compilazione per riabilitare il checkbox
    		vm.ordine.stato="IN_COMPILAZIONE";
        	vm.addMessage("Non è stato possibile inviare l'Ordine, correggere l'errore segnalato e inviarlo nuovamente.");
            vm.inviaEnabled = false;
        }

        function addMessage (msg) {
        	if (vm.error==null||vm.error=="") {
        		vm.error=msg;
        		vm.errorRows=vm.errorRows+Math.ceil(msg.length/80);
        	}		
        	else {
        		vm.error=vm.error+"\n\n"+msg;
        		vm.errorRows=vm.errorRows+Math.ceil(msg.length/80)+1;
        	}
        }

    }
})();
