(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('DecisioneDetailController', DecisioneDetailController);

    DecisioneDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Decisione', 'Documento', 'VoceBilancio', 'Impegno', 'Utente', 'DocumentoFile'];

    function DecisioneDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Decisione, Documento, VoceBilancio, Impegno, Utente, DocumentoFile) {
        var vm = this;

        vm.decisione = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.openStorageFile = openStorageFile;

        function openStorageFile (documento) {
        	var	type = documento.fileContentType;
        	var data = documento.file;
        	
        	if (documento.simpleStorageService) {
        		DocumentoFile.get({id : documento.id}, function(result) {
        			console.log(result.fileContentType);
        			//console.log(result.file);
         			vm.openFile(result.fileContentType, result.file);
                }, onGetFileError);
        	} else
            	vm.openFile(type, data);
        }

        // inca$ - caricamento file solo se da DB
        {
        	if (entity.documentoPdf!=null&&!entity.documentoPdf.simpleStorageService)
	    		DocumentoFile.get({id : entity.documentoPdf.id}, function(result) {
	     			vm.decisione.documentoPdf["file"]=result.file;
	            });
        }

		var unsubscribe = $rootScope.$on('ordiniApp:decisioneUpdate', function(event, result) {
            vm.decisione = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
