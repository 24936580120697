(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .factory('FinanziamentoSearch', FinanziamentoSearch);

    FinanziamentoSearch.$inject = ['$resource'];

    function FinanziamentoSearch($resource) {
        var resourceUrl =  'api/_search/finanziamentos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
