(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .factory('AdminCommands', AdminCommands);

    AdminCommands.$inject = ['$http'];

    function AdminCommands ($http) {
        var service = {
        	updateStatoDecisione: updateStatoDecisione,
        	updateStatoDichiarazione: updateStatoDichiarazione,
        	updateStatoOrdine: updateStatoOrdine
        };

        return service;
        
        function updateStatoDecisione (vmp, id, stato) {
            return $http({
        			url: 'api/update-stato-decisione/', 
        			method: 'POST',
        			params: {id: id, stato: stato}
            	}).then(getResponse, onError);

            function getResponse (response) {
            	vmp.updateStatoDone(response);
            	return response;
            }

            function onError (response) {
            	return response;
            }
        }

        function updateStatoDichiarazione (vmp, id, stato) {
            return $http({
        			url: 'api/update-stato-dichiarazione/', 
        			method: 'POST',
        			params: {id: id, stato: stato}
            	}).then(getResponse, onError);

            function getResponse (response) {
            	vmp.updateStatoDone(response);
            	return response;
            }

            function onError (response) {
            	return response;
            }
        }
        
        function updateStatoOrdine (vmp, id, stato) {
            return $http({
        			url: 'api/update-stato-ordine/', 
        			method: 'POST',
        			params: {id: id, stato: stato}
            	}).then(getResponse, onError);

            function getResponse (response) {
            	vmp.updateStatoDone(response);
            	return response;
            }

            function onError (response) {
            	return response;
            }
        }

    }
})();
