(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .factory('StorageMigrationService', StorageMigrationService);

    StorageMigrationService.$inject = ['$resource'];

    function StorageMigrationService ($resource) {
        var service = $resource('management/storage/migrate/:id', {}, {
            'migrate': {
                method: 'GET'
            },
            'migrateByDate': {
                method: 'GET',
                params: {fromDate: null, toDate: null}
	        }
        });

        return service;
    }
})();
