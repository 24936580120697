(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('DocumentoDetailController', DocumentoDetailController);

    DocumentoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Documento', 'Decisione', 'Dichiarazione', 'DocumentoFile'];

    function DocumentoDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Documento, Decisione, Dichiarazione, DocumentoFile) {
        var vm = this;

        vm.documento = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.openStorageFile = openStorageFile;

        function openStorageFile (type, data) {
        	if (entity.simpleStorageService) {
        		DocumentoFile.get({id : entity.id}, function(result) {
        			//console.log(result.fileContentType);
        			//console.log(result.file);
         			vm.openFile(result.fileContentType, result.file);
                }, onGetFileError);
        	} else
            	vm.openFile(type, data);
        }

        function onGetFileError () {
        	modalScrollTop();
        };	

        // inca$ - caricamento file solo se da DB
        {
        	if (entity.file!=null&&!entity.simpleStorageService)
	    		DocumentoFile.get({id : entity.id}, function(result) {
	     			vm.documento["file"]=result.file;
	            });
        }

        var unsubscribe = $rootScope.$on('ordiniApp:documentoUpdate', function(event, result) {
            vm.documento = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
