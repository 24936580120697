(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('DichiarazioneDeleteController',DichiarazioneDeleteController);

    DichiarazioneDeleteController.$inject = ['$uibModalInstance', 'entity', 'Dichiarazione'];

    function DichiarazioneDeleteController($uibModalInstance, entity, Dichiarazione) {
        var vm = this;

        vm.dichiarazione = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Dichiarazione.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
