function modalScrollTop() {
	// inca$ - blocco di codice per fare scroll to top

	// copiamo tutti i valori delle setzioni
	// che andremo a cambiare per fare lo scroll
	var css1 = jQuery('.modal-content').css('overflow');
	var css2 = jQuery('.modal-content').css('max-height');
	var css3 = jQuery('.modal-body').css('overflow');
	var css4 = jQuery('.modal-body').css('max-height');

	// impostiamo i valori neccessari per lo scroll
	jQuery('.modal-content').css({'overflow': 'auto'});
	jQuery('.modal-content').css({'max-height': 60});
	jQuery('.modal-body').css({'overflow': 'auto'});
	jQuery('.modal-body').css({'max-height': 60});

	// effettuiamo lo scroll to top
	jQuery('.modal-body').animate({ scrollTop: "0px" });

	// reimpostiamo i valori a quelli corretti
	jQuery('.modal-content').css({'overflow': css1});
	jQuery('.modal-content').css({'max-height': css2});
	jQuery('.modal-body').css({'overflow': css3});
	jQuery('.modal-body').css({'max-height': css4});
};
