(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('ProgettoDeleteController',ProgettoDeleteController);

    ProgettoDeleteController.$inject = ['$uibModalInstance', 'entity', 'Progetto'];

    function ProgettoDeleteController($uibModalInstance, entity, Progetto) {
        var vm = this;

        vm.progetto = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Progetto.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
