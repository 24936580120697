(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('OperazioneController', OperazioneController);

    OperazioneController.$inject = ['$scope', '$state', 'Operazione', 'OperazioneSearch'];

    function OperazioneController ($scope, $state, Operazione, OperazioneSearch) {
        var vm = this;
        
        vm.operaziones = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            Operazione.query(function(result) {
                vm.operaziones = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            OperazioneSearch.query({query: vm.searchQuery}, function(result) {
                vm.operaziones = result;
            });
        }    
    }
})();
