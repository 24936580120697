(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('DichiarazioneDialogController', DichiarazioneDialogController);

    DichiarazioneDialogController.$inject = ['$filter', '$window', '$timeout', '$scope', '$stateParams', '$uibModal', '$uibModalInstance', '$q', 'Permissions', 'MailSigns', 'DataUtils', 'entity', 'DocumentoDBSearch', 'Dichiarazione', 'Decisione', 'Documento', 'DocumentoFile', 'AdminCommands', 'Currencies'];

    function DichiarazioneDialogController ($filter, $window, $timeout, $scope, $stateParams, $uibModal, $uibModalInstance, $q, Permissions, MailSigns, DataUtils, entity, DocumentoDBSearch, Dichiarazione, Decisione, Documento, DocumentoFile, AdminCommands, Currencies) {
        var vm = this;
        
        // inca$ - variabili gestione preview e invio
        vm.visualizza = false;
        vm.invia = false;
        vm.isPreview = false; // quando viene premuto Preview, isPreview viene settata definitivamente a true;
        vm.isValidating = false; // quando viene premuto Valida o Respingi, isValidating viene settato definitivamente a true;
        vm.oldStato;
        vm.dateformat='dd/MM/yyyy';
        $scope.functionNameForAutocomplete = 'dichiarazione'; // per gestire l'autocompletamento

        vm.dichiarazione = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.downloadFile = DataUtils.downloadFile;
        vm.previewFile = DataUtils.previewFile;
        vm.openStorageFile = openStorageFile;
        vm.downloadStorageFile = downloadStorageFile;
        vm.documentoFileName = documentoFileName;
        vm.docFileName = docFileName;
        vm.save = save;
        vm.save2 = save2;
        vm.save3 = save3;
    	vm.utenteLogin = Permissions.getAccount().login;
//        vm.documentopdfs = Documento.query({filter: 'dichiarazionepdf-is-null'});
//        $q.all([vm.dichiarazione.$promise, vm.documentopdfs.$promise]).then(function() {
//            if (!vm.dichiarazione.documentoPdf || !vm.dichiarazione.documentoPdf.id) {
//                return $q.reject();
//            }
//            return Documento.get({id : vm.dichiarazione.documentoPdf.id}).$promise;
//        }).then(function(documentoPdf) {
//            vm.documentopdfs.push(documentoPdf);
//        });
        vm.decisiones = Decisione.query({filter: 'dichiarazione-is-null', valid: true});
        $q.all([vm.dichiarazione.$promise, vm.decisiones.$promise]).then(function() {
            if (!vm.dichiarazione.decisione || !vm.dichiarazione.decisione.id) {
                return $q.reject();
            }
            return Decisione.get({id : vm.dichiarazione.decisione.id}).$promise;
        }).then(function(decisione) {
            vm.decisiones.push(decisione);
        });
        //vm.documentos = Documento.query();
        // inca$ - gestione validazione e rifiuto
        vm.validate = validate;
        vm.reject = reject;
        vm.disableSave = disableSave; // inca$ - imposta l'abilitazione di Salva
        vm.disableValidateOrReject = disableValidateOrReject; // inca$ - imposta l'abilitazione dei button Valida e Respingi
        vm.disablePreviewOrSend = disablePreviewOrSend;
        vm.showMotivazioneRifiuto = showMotivazioneRifiuto;
        vm.showProtocollo = showProtocollo;
        vm.disableProtocollo = disableProtocollo;
        // inca$ - aggiunta gestione rifiuto
        vm.rejectDialogForm = rejectDialogForm;
        vm.eraseHiddenFields = eraseHiddenFields;
        vm.attachForm = attachForm;
        vm.documentiAllegati = null;
        vm.findAttachedDocs = findAttachedDocs;
        vm.deleteAttachedDoc = deleteAttachedDoc;
        vm.downloadAttachedDoc = downloadAttachedDoc;
        vm.disableInsertDetails = disableInsertDetails;
        vm.today = today;
        // inca$ - gestione currencies
        vm.currencies = null;
        //inca$ - gestione invio mail documenti
        vm.sendMailSigns = sendMailSigns;
        vm.viewMailSigns = viewMailSigns;
        vm.mailSent = mailSent;
        //inca$ - gestione fuzioni superutente
        vm.viewUpdateStato = viewUpdateStato;
        vm.updateStato = updateStato;
        vm.statoForUpdate = vm.dichiarazione.stato;
        vm.updateStatoDone = updateStatoDone;
        vm.currencySymbol = currencySymbol;
        // inca$ - aggiunta gestione invio multiplo
        vm.sendDialogForm = sendDialogForm;
        vm.editDecisione = editDecisione;
        vm.decisioneChanged = decisioneChanged;
        // inca$ - gestione stato colorato
        vm.statoColour = statoColour;
        vm.statoLinkedColour = statoLinkedColour;
        vm.motivazioneSceltaFornitoreBase = motivazioneSceltaFornitoreBase;
        vm.fornitoreScelto="";

        function motivazioneSceltaFornitoreBase () {
        	var territorio = vm.dichiarazione.territorio;
        	if (territorio==null)
        		territorio="";
        	else
        		territorio=" per il territorio "+territorio.toLowerCase();
        	// il fornitore è stato scelto senza nessuna comparazione ed è presente nè dichiarazione di esclusività nè di unicità
        	var fornitoreScelto = "l’operatore economico "+vm.dichiarazione.fornitoreScelto+" è stato scelto in base alla seguente motivazione: ";
        	// il fornitore è stato scelto senza nessuna comparazione ed è presente una dichiarazione di esclusività
        	var fornitoreSceltoNCEsclusivita = "è stata acquisita un'unica offerta dall’operatore economico  "+vm.dichiarazione.fornitoreScelto+",  scelto in base alla seguente motivazione: gli articoli da acquisire sono distribuiti solo dall’operatore economico  "+vm.dichiarazione.fornitoreScelto+territorio+" come da dichiarazione di esclusività fornita dall’operatore economico insieme all’offerta";
        	// il fornitore è stato scelto senza nessuna comparazione ed è presente una dichiarazione di unicità
        	var fornitoreSceltoNCUnicita = "è stata acquisita un'unica offerta dall’operatore economico "+vm.dichiarazione.fornitoreScelto+", scelto in base alla seguente motivazione: le caratteristiche degli articoli da acquisire sono insostituibili e non riscontrabili in altri prodotti come da dichiarazione di unicità fornita dall’operatore economico insieme all’offerta";

        	if (vm.documentiAllegati!=null) {
            	for (var j = 0, len = vm.documentiAllegati.length; j < len; j++) {
            		if (vm.documentiAllegati[j].tipo=='DICHIARAZIONE_ESCLUSIVITA') {
                		return fornitoreSceltoNCEsclusivita;
            		}
            	}
            	for (var j = 0, len = vm.documentiAllegati.length; j < len; j++) {
            		if (vm.documentiAllegati[j].tipo=='DICHIARAZIONE_UNICITA') {
                		return fornitoreSceltoNCUnicita;
            		}
            	}
        	}
        	// il fornitore è stato scelto senza nessuna comparazione ed è presente nè dichiarazione di esclusività nè di unicità
        	vm.fornitoreScelto=fornitoreScelto;
        	return fornitoreScelto;
        };	

        // all'ingresso della funzione, consideriamo lo stato
    	// di rifiutato come se fosse in compilazione
        {
       		// inca$ - gestione chiusura e invio
        	if ((vm.dichiarazione.stato == 'RIFIUTATO_DA_AMMINISTRAZIONE' ||
            	vm.dichiarazione.stato == 'RIFIUTATO_DA_RESPONSABILE') &&
        		((vm.dichiarazione.utenteCreazione == vm.utenteLogin ||
        		vm.dichiarazione.decisione.responsabileProcedimento.user.login == vm.utenteLogin) ||
        		Permissions.hasRole('ROLE_SUPERUTENTE'))) {
				vm.dichiarazione.stato = 'IN_COMPILAZIONE';
				//vm.dichiarazione.file = null;
        	}
        }
        
        function openStorageFile (documento) {
        	var	type = documento.fileContentType;
        	var data = documento.file;
        	
        	if (documento.simpleStorageService) {
        		DocumentoFile.get({id : documento.id}, function(result) {
        			console.log(result.fileContentType);
        			//console.log(result.file);
         			vm.openFile(result.fileContentType, result.file);
                }, onGetFileError);
        	} else
            	vm.openFile(type, data);
        }

        function downloadStorageFile (documento, filename) {
        	var	type = documento.fileContentType;
        	var data = documento.file;

        	if (documento.simpleStorageService) {
        		DocumentoFile.get({id : documento.id}, function(result) {
        			console.log(result.fileContentType);
        			//console.log(result.file);
         			vm.downloadFile(result.fileContentType, result.file, filename);
                }, onGetFileError);
        	} else
            	vm.downloadFile(type, data, filename);
        }

        function onGetFileError () {
        	modalScrollTop();
        };	

        function statoLinkedColour (stato, tipoDocumento, classField) {
        	if (stato=='IN_COMPILAZIONE')
        		classField = classField+" stato-field-compilatore";
        	else if (stato=='INVIATO') {
        		if (tipoDocumento=='decisione')
        			classField = classField+" stato-field-amministrazione";
        		else
        			classField = classField+" stato-field-responsabile";
        	}
        	else if (stato=='RIFIUTATO_DA_RESPONSABILE')
        		classField = classField+" stato-field-rifiuto";
        	else if (stato=='INVIATO_A_AMMINISTRAZIONE')
        		classField = classField+" stato-field-amministrazione";
        	else if (stato=='RIFIUTATO_DA_AMMINISTRAZIONE')
        		classField = classField+" stato-field-rifiuto";
        	else if (stato=='INVIATO_A_DIRETTORE')
        		classField = classField+" stato-field-direttore";
        	else if (stato=='RIFIUTATO_DA_DIRETTORE')
        		classField = classField+" stato-field-rifiuto";
        	else if (stato=='VALIDATO')
        		classField = classField+" stato-field-completato";
        	return classField;
        }

        function statoColour (stato) {
        	//return "stato-visibile-field form-control"; // default
        	return statoLinkedColour (stato, 'dichiarazione', "stato-visibile-field");
        }

        function today () {
        	return new Date();
        }

        // inca$ - caricamento file solo se da DB
        {
        	if (entity.documentoPdf!=null&&!entity.documentoPdf.simpleStorageService)
	    		DocumentoFile.get({id : entity.documentoPdf.id}, function(result) {
	     			vm.dichiarazione.documentoPdf["file"]=result.file;
	            });
        }

        // inca$ - inizializzazione caricamento ruoli
        Permissions.loadRoles();

        // inca$ - inizializzazione caricamento currencies
        Currencies.loadCurrencyList(vm);

        // inca$ - carico i documenti allegati
        vm.findAttachedDocs();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
            
            // inca$ - serve come workaround per un bug del ui-datepicker che azzera
            // i campi quando si richiama la funzione da un altra funzione
            if (vm.dichiarazione.id!=null) {
                $scope.editForm.data.$setValidity("required", true);
    	        $scope.editForm.$invalid = false;
            }

        });

        function decisioneChanged () {
        	if (vm.dichiarazione.decisione!=null) {
        		vm.dichiarazione.ordineMepa = !vm.dichiarazione.decisione.funzionaleAllaRicerca
        		vm.dichiarazione.descrizione = vm.dichiarazione.decisione.descrizione;
        	}
        }
        
        function clear () {
        	// inca$ - chiudiamo solo se non è stata fatta preview
        	if (!vm.isPreview)
        		$uibModalInstance.dismiss('cancel');
        }

        function save () {

        	vm.eraseHiddenFields();

       		// memorizziamo SEMPRE lo stato precedente al salvataggio
       		vm.oldStato = vm.dichiarazione.stato;

        	// inca$ - gestione chiusura e invio
        	if (vm.dichiarazione.stato==null)
        		vm.dichiarazione.stato='IN_COMPILAZIONE';

//    		if (vm.visualizza!=true&&vm.isSaving!=true) {
//                if (vm.dichiarazione.stato=='IN_COMPILAZIONE') {
//        			vm.dichiarazione.file=null;
//        		}
//    		}

        	if (vm.invia==true) {
        		
        		// se si volesse inviare direttamente senza dialog di invio multiplo
        		// basta attivare save2() e disattivare sendDialogForm(); 
        		// save2(); 
                sendDialogForm();

        	} else
                save3();
        }

        function save2 () {
    		if (vm.dichiarazione.stato=='IN_COMPILAZIONE')
    			if (vm.dichiarazione.decisione.impegno.gae.finanziamento.responsabileFondi.direttore == true ||
    				vm.dichiarazione.decisione.impegno.gae.finanziamento.responsabileFondi.user.login == vm.utenteLogin)
    				vm.dichiarazione.stato='INVIATO_A_AMMINISTRAZIONE';
    			else
    				vm.dichiarazione.stato='INVIATO';
            vm.invia = false;
        }

        function save3 () {
            vm.isSaving = true;
            if (vm.dichiarazione.id !== null) {
				if (vm.dichiarazione.documentoPdf != null &&
						(vm.dichiarazione.documentoPdf.annoProtocollo != null ||
						vm.dichiarazione.documentoPdf.numeroProtocollo != null)) {
					Documento.update(vm.dichiarazione.documentoPdf);
				}
                Dichiarazione.update(vm.dichiarazione, onSaveSuccess, onSaveError);
            } else {
                Dichiarazione.save(vm.dichiarazione, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
			// inca$ - gestione preview
			if (vm.visualizza == true) {
				vm.dichiarazione=result;
				$scope.editForm.$setValidity();
	            $scope.$emit('ordiniApp:dichiarazioneUpdate', result);
	        	// inca$ - non più funzionante su chrome a causa dell'errore
	        	// Not allowed to navigate top frame to data URL
				//$window.open('data:' + result.documentoPdf.fileContentType + ';base64,' + result.documentoPdf.file);
	            var type = result.documentoPdf.fileContentType;
	            var data = result.documentoPdf.file;
	        	vm.previewFile(type, data);

				// sono costretto a settare il valore delle date a causa di un azzeramento per il comportamento anomalo
				// della proprietà uib-datepicker-popup="{{vm.dateformat}}"
				var data = angular.element(document.querySelector('#field_data'));
				data.val($filter('date')(result.data,vm.dateformat));

				vm.visualizza=false;
			} else {
	            $scope.$emit('ordiniApp:dichiarazioneUpdate', result);
	            $uibModalInstance.close(result);
			}
            vm.isSaving = false;
        }

        function onSaveError () {

        	// inca$ - mia funzione per fare scroll to top
        	modalScrollTop();

            vm.isSaving = false;
        	// inca$ - gestione preview
       		vm.isPreview=false;

       		// torniamo SEMPRE allo stato precedente in caso di errore
       		vm.dichiarazione.stato = vm.oldStato;

       		// inca$ - gestione valida o respingi
			if (vm.isValidating == true) {
    			//vm.dichiarazione.stato = vm.oldStato;
				vm.isValidating = false;
			}
        }

    	// inca$ - gestione preview
        vm.preview = function () {
        	vm.visualizza=true;
        	vm.isPreview = true;
        }

        // inca$ - gestione invio
        vm.send = function () {
        	vm.invia=true;
        }

        vm.datePickerOpenStatus.data = false;

        vm.setFile = function ($file, dichiarazione) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        dichiarazione.file = base64Data;
                        dichiarazione.fileContentType = $file.type;
                    });
                });
            }
        };
        vm.datePickerOpenStatus.dataCreazione = false;
        vm.datePickerOpenStatus.dataAggiornamento = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function validate () {

            // inca$ - gestione validazione
        	if (vm.dichiarazione.stato == 'INVIATO') {
    			vm.dichiarazione.stato = 'INVIATO_A_AMMINISTRAZIONE';

    			vm.isValidating = true;
    			vm.oldStato = 'INVIATO';
    			vm.isSaving = true;
        		Dichiarazione.update(vm.dichiarazione, onSaveSuccess, onSaveError);

			} else if (vm.dichiarazione.stato == 'INVIATO_A_AMMINISTRAZIONE') {
				vm.dichiarazione.stato = 'VALIDATO';

				vm.isValidating = true;
				vm.oldStato = 'INVIATO_A_AMMINISTRAZIONE';
				vm.isSaving = true;
				Dichiarazione.update(vm.dichiarazione, onSaveSuccess, onSaveError);

			}
        }

        function reject () {

        	// inca$ - gestione rifiuto
    		if (vm.dichiarazione.stato == 'INVIATO') {
    			vm.dichiarazione.stato = 'RIFIUTATO_DA_RESPONSABILE';

    			vm.isValidating = true;
    			vm.oldStato = 'INVIATO';
    			vm.isSaving = true;

    			Dichiarazione.update(vm.dichiarazione, onSaveSuccess, onSaveError);

    		} else if (vm.dichiarazione.stato == 'INVIATO_A_AMMINISTRAZIONE') {
				vm.dichiarazione.stato = 'RIFIUTATO_DA_AMMINISTRAZIONE';

				vm.isValidating = true;
				vm.oldStato = 'INVIATO_A_AMMINISTRAZIONE';
				vm.isSaving = true;

				Dichiarazione.update(vm.dichiarazione, onSaveSuccess, onSaveError);

			}

        }

        // inca$ - gestione save, preview o send
        function disableSave () {

        	if (vm.dichiarazione.stato == null) {
        		return false;
        	}

        	// permetto l'amministrazione di inserire il protocollo
        	if (!disableProtocollo())
        		return false;

        	return disableSavePreviewOrSend();
        }

        // inca$ - gestione inserimento allegati e dettagli
        function disableInsertDetails () {

        	if (disableSave())
        		return true;

        	if (vm.dichiarazione.stato == null) {
        		return true;
        	}

			if (vm.dichiarazione.stato != 'IN_COMPILAZIONE') {
				return true;
			} else {
				return false;
			}

        }

        // inca$ - gestione save, preview o send
        function disablePreviewOrSend () {

        	if (vm.dichiarazione.stato == null) {
        		return true;
        	}
        	return disableSavePreviewOrSend();

        }

        // inca$ - gestione save, preview o send
        function disableSavePreviewOrSend () {

           	// ricordiamoci che il direttore potrebbe essere che sia lui a creare la decisione di contrattare
           	// oppure sia lui il responsabile del procedimento, pertanto va prima verificato questa condizione
           	// successivamente quella del ruolo direttore
           	if (vm.dichiarazione.decisione.responsabileProcedimento.user.login == vm.utenteLogin ||
				vm.dichiarazione.utenteCreazione == vm.utenteLogin||
				Permissions.hasRole('ROLE_SUPERUTENTE')) {

				if ((vm.dichiarazione.stato == 'INVIATO')||
						(vm.dichiarazione.stato == 'INVIATO_A_AMMINISTRAZIONE')||
						(vm.dichiarazione.stato == 'VALIDATO')) {
					return true;
				} else {
					return false;
				}
    		} else if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE']) &&
        			vm.dichiarazione.stato == 'VALIDATO')) {
       			return true;
    		}

       		return true;
        }

        // inca$ - gestione valida o respingi
        function disableValidateOrReject () {

			if (vm.dichiarazione.stato == null)
				return true;

    		if ((((Permissions.hasOneOfRoles(['ROLE_SUPERUTENTE']) || vm.dichiarazione.decisione.impegno.gae.finanziamento.responsabileFondi.user.login == vm.utenteLogin)) && vm.dichiarazione.stato == 'INVIATO') ||
       			(Permissions.hasOneOfRoles(['ROLE_SUPERUTENTE','ROLE_AMMINISTRAZIONE']) && vm.dichiarazione.stato == 'INVIATO_A_AMMINISTRAZIONE')) {
    			return false;
    		}
       		return true;
        }

        // inca$ - gestione campo motivazione rifiuto
        function showMotivazioneRifiuto () {

			if (vm.dichiarazione.stato == null)
				return false;

			if (vm.dichiarazione.motivazioneRifiuto != null)
				return true;

			if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_SUPERUTENTE']) ||
					(vm.dichiarazione.stato == 'RIFIUTATO_DA_AMMINISTRAZIONE' || vm.dichiarazione.stato == 'RIFIUTATO_DA_RESPONSABILE')))
    			return true;

			return false;
        }

        // inca$ - gestione campo protocollo
        function showProtocollo () {

			if (vm.dichiarazione.stato == null)
				return false;

			if (vm.dichiarazione.documentoPdf==null)
				return false;

			if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_SUPERUTENTE']) ||
					vm.dichiarazione.documentoPdf.numeroProtocollo !=null))
    			return true;

			return false;
        }

        // inca$ - gestione campo protocollo
        function disableProtocollo () {

			if (vm.dichiarazione.stato == null)
				return true;

			if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_SUPERUTENTE']) &&
					vm.dichiarazione.stato == 'VALIDATO'))
    			return false;

			return true;
        }

        // inca$ - aggiunta gestione rifiuto
        function rejectDialogForm() {
        	$uibModal.open({
                templateUrl: 'app/util/entity-reject.html',
                controller: 'EntityRejectController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    controller: function() {
                        return vm;
                    },
                    entityName: function() {
                        return 'dichiarazione';
                    }
                }
            }).result.then(function() {
            	vm.reject();
            }, function() {
            });
        }

        // inca$ - aggiunta gestione rifiuto
        function sendDialogForm() {
        	$uibModal.open({
                templateUrl: 'app/util/send/multiple-send.html',
                controller: 'MultipleSendController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    controller: function() {
                        return vm;
                    },
                    entityName: function() {
                        return 'dichiarazione';
                    },
                    entity: function() {
                        return vm.dichiarazione;
                    }
                }
            }).result.then(function(result) {
            	if (result) 
            		vm.save2();
                vm.save3();

            }, function() {
                vm.invia = false;
            });
        }

        function eraseHiddenFields () {

        	if (vm.dichiarazione.ordineMepa!=null &&
        			vm.dichiarazione.ordineMepa==false) {
        		vm.dichiarazione.strumentoMepaScelto=null;
        		vm.dichiarazione.sezioneMepa=null;
        		vm.dichiarazione.criteriRicercaArticoli=null;
        		vm.dichiarazione.numeroOfferteMepa=null;
        	}

        	if (vm.dichiarazione.beneInvantariabile!=null &&
        			vm.dichiarazione.beneInvantariabile==false) {
        		vm.dichiarazione.edificio=null;
        		vm.dichiarazione.indirizzo=null;
        		vm.dichiarazione.numeroStanza=null;
        		vm.dichiarazione.nomeAssegnatarioBene=null;
        	}

        	if (vm.documentiAllegati!=null) {
        		var exist = false;
            	for (var j = 0, len = vm.documentiAllegati.length; j < len; j++) {
            		if (vm.documentiAllegati[j].tipo=='DICHIARAZIONE_ESCLUSIVITA') {
            			exist = true;
            			break;
            		}
            	}
            	if (!exist)
            		vm.dichiarazione.territorio=null;
        	}
        		
        }

        function attachForm () {

        	findAttachedDocs ();

        	$uibModal.open({
                templateUrl: 'app/entities/documento/documento-attach.html',
                controller: 'DocumentoAttachController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('documento');
                        $translatePartialLoader.addPart('tipoDocumento');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
						return {
						    tipo: null,
						    file: null,
						    fileContentType: null,
						    simpleStorageService: false,
						    annoProtocollo: null,
						    numeroProtocollo: null,
						    id: null
						}
                    },
                    controller: function() {
                        return vm;
                    },
                    entityName: function() {
                        return 'dichiarazione';
                    }
                }
            }).result.then(function() {
            	vm.findAttachedDocs();
            }, function() {
            });
        }

        function findAttachedDocs () {
        	if (vm.dichiarazione.id!=null)
        		vm.documentiAllegati = DocumentoDBSearch.query({size: 100000000, query: {dichiarazioneId: vm.dichiarazione.id}}, dettagliOnSuccess);

        	function dettagliOnSuccess(data, headers) {
            	for (var i = 0, len = vm.documentiAllegati.length; i < len; i++) {
            		if (!vm.documentiAllegati[i].simpleStorageService)
	            		DocumentoFile.get({id : vm.documentiAllegati[i].id}, function(result) {
	                    	for (var j = 0, len2 = vm.documentiAllegati.length; j < len2; j++) {
	                    		if (vm.documentiAllegati[j].id==result.id) {
	                    			vm.documentiAllegati[j]["file"]=result.file;
	                    			break;
	                    		}
	                    	}
	                    });
                }
            }
        }

        function deleteAttachedDoc (documento) {

        	findAttachedDocs ();

        	$uibModal.open({
                templateUrl: 'app/entities/documento/documento-delete-dialog.html',
                controller: 'DocumentoDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: function() {
                        return documento;
                    }
                }
            }).result.then(function() {
            	vm.findAttachedDocs();
            }, function() {
            });

        };

        // questo metodo non funziona su firefox e atri browser vecchi
        function downloadAttachedDoc (documento) {

        	var binaryImg = atob(documento.file);
        	var length = binaryImg.length;
        	var arrayBuffer = new ArrayBuffer(length);
        	var uintArray = new Uint8Array(arrayBuffer);
        	for (var i = 0; i < length; i++) {
        	    uintArray[i] = binaryImg.charCodeAt(i);
        	}

            var url = URL.createObjectURL(new Blob([uintArray]));
            var a = document.createElement('a');
            a.href = url;

            var extn = documento.fileContentType.split("/").pop();
            if (extn!=null)
            	a.download = 'allegato_'+documento.id+"."+extn;
            else
            	a.download = 'allegato_'+documento.id;

            a.target = '_blank';
            a.click();
        }

        function editDecisione () {
        	
        	if (vm.dichiarazione!=null) {
        		
	        	$uibModal.open({
	              templateUrl: 'app/entities/decisione/decisione-dialog.html',
	              controller: 'DecisioneDialogController',
	                controllerAs: 'vm',
	                backdrop: 'static',
	                size: 'md',
	                resolve: {
	                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                        $translatePartialLoader.addPart('decisione');
	                        $translatePartialLoader.addPart('tipoOrdine');
	                        $translatePartialLoader.addPart('tipoDecisione');
	                        $translatePartialLoader.addPart('statoDecisione');
	                        $translatePartialLoader.addPart('documento'); // inca$ - aggiungo la traduzione di documento
	                        $translatePartialLoader.addPart('global');
	                        return $translate.refresh();
	                    }],
	                    entity: ['Decisione', function(Decisione) {
	                        return Decisione.get({id : vm.dichiarazione.decisione.id}).$promise;
	                    }],
	                    controller: function() {
	                        return vm;
	                    },
	                    entityName: function() {
	                        return 'decisione';
	                    },
	                }
	            }).result.then(function(decisione) {
	            	vm.dichiarazione.decisione=decisione;
	            	
                    // ricarichiamo il valore nella select list
        		    $q.all([vm.dichiarazione.$promise, vm.decisiones.$promise]).then(function() {
        	            if (!decisione || !decisione.id) {
        	                return $q.reject();
        	            }
        	            return Decisione.get({id : decisione.id}).$promise;
        	        }).then(function(decisioneLoaded) {
    	            	for (var i=0;i<vm.decisiones.length;i++){
    	   	        		if (vm.decisiones[i].id==decisione.id) {
    	   	        			vm.decisiones.splice(i,1);
    	   	        		}
    	            	}
         	            vm.decisiones.push(decisioneLoaded);
    	        		if (decisioneLoaded.id==decisione.id) {
		        			vm.dichiarazione.decisione=decisioneLoaded;
		        		}
        	        });
        		    
        		    // ricarichiamo il file dato che potrebbe essere stato rigenerato
        		    if (entity.documentoPdf!=null&&!entity.documentoPdf.simpleStorageService)
        	    		DocumentoFile.get({id : entity.documentoPdf.id}, function(result) {
        	     			vm.dichiarazione.documentoPdf["file"]=result.file;
        	            });

        	    
	            }, function() {
	            });
        	}
        }

        function viewMailSigns () {
    		
        	if (vm.dichiarazione.stato == null)
    			return false;

    		if (Permissions.hasOneOfRoles(['ROLE_SUPERUTENTE']) && 
    				vm.dichiarazione.stato == 'VALIDATO' &&
    				vm.dichiarazione.ordineMepa == true)
    			return true;
    		
    		return false;
        }

        function sendMailSigns () {
        	MailSigns.sendMailMepa(vm, vm.dichiarazione.id);
        }
    
        function mailSent (result) {
        	$uibModalInstance.dismiss('cancel');
        }

        function viewUpdateStato () {
    		
        	if (vm.dichiarazione.stato == null)
    			return false;

    		if (Permissions.hasOneOfRoles(['ROLE_SUPERUTENTE'])) 
    			return true;
    		
    		return false;
        }

        function updateStato () {
        	AdminCommands.updateStatoDichiarazione(vm, vm.dichiarazione.id, vm.statoForUpdate);
        }

        function updateStatoDone (result) {
        	$uibModalInstance.dismiss('cancel');
        }

        function currencySymbol () {
        	if (vm.dichiarazione.valuta==null)
        		return "€";
        	
        	var symbol = currencySymbols[vm.dichiarazione.valuta];
        	
        	if (symbol!=null)
                return symbol;
        	else
                return vm.dichiarazione.valuta;
               
        }

        function documentoFileName (documento, numero) {
        	return findAndReplace(documento.dichiarazione.decisione.impegno.codice,"/","_").replace(/[|&;$%@"<>()+,]/g, "")+"_"+documento.tipo.toLowerCase()+"_"+(numero+1)+"_ID_"+documento.id;
        }
        
        function docFileName (dichiarazione, fileContentType) {
        	var extn = null;
        	if (dichiarazione.documentoPdf.fileContentType!=null)
        		extn = dichiarazione.documentoPdf.fileContentType.split("/").pop();
        	else
        		extn = fileContentType.split("/").pop();
        	return findAndReplace(dichiarazione.decisione.impegno.codice,"/","_").replace(/[|&;$%@"<>()+,]/g, "")+"_dichiarazione_RUP_ID_"+dichiarazione.id+"."+extn;
        }

        function findAndReplace(string, target, replacement) {
        	 var i = 0, length = string.length;
        	 for (i; i < length; i++) {
        	   string = string.replace(target, replacement);
        	 }
        	 return string;
        }
    }
})();
