(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('DecisioneDialogController', DecisioneDialogController);

    DecisioneDialogController.$inject = ['$filter', '$window', '$timeout', '$scope', '$stateParams', '$uibModal', '$uibModalInstance', '$q', 'Permissions', 'DataUtils', 'entity', 'Decisione', 'Documento', 'VoceBilancio', 'Impegno', 'Utente', 'DocumentoFile', 'AdminCommands', 'ImpegnoDBSearch'];

    function DecisioneDialogController ($filter, $window, $timeout, $scope, $stateParams, $uibModal, $uibModalInstance, $q, Permissions, DataUtils, entity, Decisione, Documento, VoceBilancio, Impegno, Utente, DocumentoFile, AdminCommands, ImpegnoDBSearch) {
        var vm = this;

        // inca$ - variabili gestione preview e invio
        vm.visualizza = false;
        vm.invia = false;
        vm.isPreview = false; // quando viene premuto Preview, isPreview viene settato definitivamente a true;
        vm.isValidating = false; // quando viene premuto Valida o Respingi, isValidating viene settato definitivamente a true;
        vm.oldStato;
        vm.dateformat='dd/MM/yyyy';
        $scope.functionNameForAutocomplete = 'decisione'; // per gestire l'autocompletamento
        
        vm.decisione = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.downloadFile = DataUtils.downloadFile;
        vm.previewFile = DataUtils.previewFile;
        vm.openStorageFile = openStorageFile;
        vm.downloadStorageFile = downloadStorageFile;
        vm.docFileName = docFileName;
        vm.save = save;
    	vm.utenteLogin = Permissions.getAccount().login;
//        vm.documentopdfs = Documento.query({filter: 'decisionepdf-is-null'});
//        $q.all([vm.decisione.$promise, vm.documentopdfs.$promise]).then(function() {
//            if (!vm.decisione.documentoPdf || !vm.decisione.documentoPdf.id) {
//                return $q.reject();
//            }
//            return Documento.get({id : vm.decisione.documentoPdf.id}).$promise;
//        }).then(function(documentoPdf) {
//            vm.documentopdfs.push(documentoPdf);
//        });
    	vm.esercizio = esercizio;
    	vm.esercizioVoce = esercizioVoce;
    	vm.loadImpegniList = loadImpegniList;
        vm.vocebilancios = VoceBilancio.query({size:100000000, filterOn: true, viewableOnly: true, esercizio: vm.esercizioVoce()}); // inca$ - caricamento totale liste fk
        //vm.impegnos = Impegno.query({size:100000000, filterOn: true, decisioneId: vm.decisione.id}); // inca$ - caricamento totale liste fk
        vm.loadImpegniList(); // carico la lista fk degli impegni
        vm.utentes = Utente.query({size:100000000}); // inca$ - caricamento totale liste fk
    	// inca$ - gestione validazione e rifiuto
        vm.validate = validate;
        vm.reject = reject;
        vm.disableSave = disableSave; // inca$ - imposta l'abilitazione di Salva
        vm.disableValidateOrReject = disableValidateOrReject; // inca$ - imposta l'abilitazione dei button Valida e Respingi
        vm.disablePreviewOrSend = disablePreviewOrSend;
        vm.showMotivazioneRifiuto = showMotivazioneRifiuto;
        vm.showProtocollo = showProtocollo;
        vm.disableProtocollo = disableProtocollo;
        // inca$ - aggiunta gestione rifiuto
        vm.rejectDialogForm = rejectDialogForm;
        vm.eraseHiddenFields = eraseHiddenFields;
        vm.today = today;
        vm.funzionaleAllaRicerca = funzionaleAllaRicerca;
        //inca$ - gestione fuzioni superutente
        vm.viewUpdateStato = viewUpdateStato;
        vm.updateStato = updateStato;
        vm.statoForUpdate = vm.decisione.stato;
        vm.updateStatoDone = updateStatoDone;
        // inca$ - gestione stato colorato
        vm.statoColour = statoColour;
        vm.rupName = rupName;
        // inca$ - gestione impegni aggiuntivi
        vm.impegniAggiuntivi = null;
        vm.impegniAggiuntiviLiberi = null;
        vm.findImpegniAggiuntivi = findImpegniAggiuntivi;
        vm.addImpegnoAggiuntivo = addImpegnoAggiuntivo;
        vm.deleteImpegnoAggiuntivo = deleteImpegnoAggiuntivo;
        vm.disableInsertDetails = disableInsertDetails;
        
        // inca$ - carico gli impegni aggiuntivi
    	vm.findImpegniAggiuntivi();
    	
    	// all'ingresso della funzione, consideriamo lo stato
    	// di rifiutato come se fosse in compilazione
    	{
       		// inca$ - gestione chiusura e invio
        	if ((vm.decisione.stato == 'RIFIUTATO_DA_AMMINISTRAZIONE' ||
        		vm.decisione.stato == 'RIFIUTATO_DA_DIRETTORE') &&
        		((vm.decisione.utenteCreazione == vm.utenteLogin ||
        		vm.decisione.responsabileProcedimento.user.login == vm.utenteLogin) ||
        		Permissions.hasRole('ROLE_SUPERUTENTE'))) {
				vm.decisione.stato = 'IN_COMPILAZIONE';
				//vm.decisione.file = null;
        	}
    	}

        function loadImpegniList () {
        	var impegno=vm.decisione.impegno;
        	vm.impegnos = Impegno.query({size:100000000, filterOn: true, decisioneId: vm.decisione.id}, dettagliOnSuccess);
            
        	function dettagliOnSuccess(data, headers) {
            	vm.decisione.impegno=impegno;
            }

        }
        
        function openStorageFile (documento) {
        	var	type = documento.fileContentType;
        	var data = documento.file;

        	if (documento.simpleStorageService) {
        		DocumentoFile.get({id : documento.id}, function(result) {
        			//console.log(result.fileContentType);
        			//console.log(result.file);
         			vm.openFile(result.fileContentType, result.file);
                });
        	} else
            	vm.openFile(type, data);
//        		if (vm.decisione.documentoPdf["file"]==null)
//		    		DocumentoFile.get({id : entity.documentoPdf.id}, function(result) {
//		    			vm.decisione.documentoPdf["file"]=result.file;
//		    			vm.openFile(type, vm.decisione.documentoPdf["file"]);
//		    		});
        }

        function downloadStorageFile (documento, filename) {
        	var	type = documento.fileContentType;
        	var data = documento.file;

        	if (documento.simpleStorageService) {
        		DocumentoFile.get({id : documento.id}, function(result) {
        			//console.log(result.fileContentType);
        			//console.log(result.file);
         			vm.downloadFile(result.fileContentType, result.file, filename);
                }, onGetFileError);
        	} else
            	vm.downloadFile(type, data, filename);
        }
        
        function onGetFileError () {
        	modalScrollTop();
        };	

        function esercizio () {
        	if (vm.decisione!=null && vm.decisione.impegno!=null)
        		return vm.decisione.impegno.esercizio;
        	if (vm.decisione!=null && vm.decisione.data!=null)
        		return vm.decisione.data.getFullYear();
        	return (new Date()).getFullYear();
        }

        function esercizioVoce () {
        	if (vm.decisione!=null && vm.decisione.voceBilancio!=null)
        		return vm.decisione.voceBilancio.esercizio;
        	if (vm.decisione!=null && vm.decisione.data!=null)
        		return vm.decisione.data.getFullYear();
        	return (new Date()).getFullYear();
        }

        function rupName () {
        	if (vm.decisione!=null)
        		return vm.decisione.responsabileProcedimento.user.firstName+" "+vm.decisione.responsabileProcedimento.user.lastName;
        	return null;
        }
        
        function statoColour (stato) {
        	//return "stato-visibile-field form-control"; // default
        	var classField = "stato-visibile-field";
        	if (stato=='IN_COMPILAZIONE')
        		classField = classField+" stato-field-compilatore";
        	else if (stato=='INVIATO')
        		classField = classField+" stato-field-amministrazione";
        	else if (stato=='RIFIUTATO_DA_AMMINISTRAZIONE')
        		classField = classField+" stato-field-rifiuto";
        	else if (stato=='INVIATO_A_DIRETTORE')
        		classField = classField+" stato-field-direttore";
        	else if (stato=='RIFIUTATO_DA_DIRETTORE')
        		classField = classField+" stato-field-rifiuto";
        	else if (stato=='VALIDATO')
        		classField = classField+" stato-field-completato";
        	return classField;
        }

        function today () {
        	return new Date();
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
            
            // inca$ - serve come workaround per un bug del ui-datepicker che azzera
            // i campi quando si richiama la funzione da un altra funzione
            if (vm.decisione.id!=null) {
                $scope.editForm.data.$setValidity("required", true);
    	        $scope.editForm.$invalid = false;
            }

        });

        // inca$ - inizializzazione caricamento ruoli
        Permissions.loadRoles();

        // inca$ - caricamento file solo se da DB
        {
        	if (entity.documentoPdf!=null&&!entity.documentoPdf.simpleStorageService)
	    		DocumentoFile.get({id : entity.documentoPdf.id}, function(result) {
	     			vm.decisione.documentoPdf["file"]=result.file;
	            });
        }

        function funzionaleAllaRicerca () {
        	if (vm.decisione.funzionaleAllaRicerca==true)
        		vm.decisione.ordineMepa=false;
        }

        function clear () {
        	// inca$ - chiudiamo solo se non è stata fatta preview
        	if (!vm.isPreview)
        		$uibModalInstance.dismiss('cancel');
        }

        function save () {

        	vm.eraseHiddenFields();

       		// memorizziamo SEMPRE lo stato precedente al salvataggio
       		vm.oldStato = vm.decisione.stato;

        	// inca$ - gestione chiusura e invio
        	if (vm.decisione.stato == null)
				vm.decisione.stato = 'IN_COMPILAZIONE';

//			if (vm.visualizza != true && vm.isSaving != true) {
//				if (vm.decisione.stato == 'IN_COMPILAZIONE') {
//					vm.decisione.file = null;
//				}
//			}

			if (vm.invia == true) {
				if (vm.decisione.stato == 'IN_COMPILAZIONE')
					vm.decisione.stato = 'INVIATO';
				vm.invia = false;
			}

			vm.isSaving = true;
			if (vm.decisione.id !== null) {
				if (vm.decisione.documentoPdf != null &&
						(vm.decisione.documentoPdf.annoProtocollo != null ||
						vm.decisione.documentoPdf.numeroProtocollo != null)) {
					Documento.update(vm.decisione.documentoPdf);
				}
				Decisione.update(vm.decisione, onSaveSuccess, onSaveError);
			} else {
				Decisione.save(vm.decisione, onSaveSuccess, onSaveError);
			}
        }

        function onSaveSuccess (result) {
			// inca$ - gestione preview
			if (vm.visualizza == true) {
				vm.decisione=result;
				$scope.editForm.$setValidity();
	            $scope.$emit('ordiniApp:decisioneUpdate', result);
	        	// inca$ - non più funzionante su chrome a causa dell'errore
	        	// Not allowed to navigate top frame to data URL
				//$window.open('data:' + result.documentoPdf.fileContentType + ';base64,' + result.documentoPdf.file);
	            var type = result.documentoPdf.fileContentType;
	            var data = result.documentoPdf.file;
	        	vm.previewFile(type, data);

				// sono costretto a settare il valore delle date a causa di un azzeramento per il comportamento anomalo
				// della proprietà uib-datepicker-popup="{{vm.dateformat}}"
				var data = angular.element(document.querySelector('#field_data'));
				data.val($filter('date')(result.data,vm.dateformat));

				vm.visualizza=false;
			} else {
	            $scope.$emit('ordiniApp:decisioneUpdate', result);
	            $uibModalInstance.close(result);
			}
            vm.isSaving = false;
        }

        function onSaveError () {

        	// inca$ - mia funzione per fare scroll to top
        	modalScrollTop();

            vm.isSaving = false;
        	// inca$ - gestione preview
       		vm.isPreview=false;

       		// torniamo SEMPRE allo stato precedente in caso di errore
       		vm.decisione.stato = vm.oldStato;

       		// inca$ - gestione valida o respingi
			if (vm.isValidating == true) {
    			//vm.decisione.stato = vm.oldStato;
				vm.isValidating = false;
			}

        }

    	// inca$ - gestione preview
        vm.preview = function () {
        	vm.visualizza=true;
       		vm.isPreview=true;
        }

        // inca$ - gestione invio
        vm.send = function () {
        	vm.invia=true;
        }

        vm.datePickerOpenStatus.data = false;

        vm.setFile = function ($file, decisione) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        decisione.file = base64Data;
                        decisione.fileContentType = $file.type;
                    });
                });
            }
        };
        vm.datePickerOpenStatus.dataCreazione = false;
        vm.datePickerOpenStatus.dataAggiornamento = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function validate () {

        	// inca$ - gestione validazione
    		if (vm.decisione.stato == 'INVIATO') {
    			vm.decisione.stato = 'INVIATO_A_DIRETTORE';

    			vm.isValidating = true;
    			vm.oldStato = 'INVIATO';
    			vm.isSaving = true;
        		Decisione.update(vm.decisione, onSaveSuccess, onSaveError);

    		} else if (vm.decisione.stato == 'INVIATO_A_DIRETTORE') {
    			vm.decisione.stato = 'VALIDATO';

    			vm.isValidating = true;
    			vm.oldStato = 'INVIATO_A_DIRETTORE';
    			vm.isSaving = true;
        		Decisione.update(vm.decisione, onSaveSuccess, onSaveError);

    		}
        }

        function reject () {

        	// inca$ - gestione rifiuto
    		if (vm.decisione.stato == 'INVIATO') {
    			vm.decisione.stato = 'RIFIUTATO_DA_AMMINISTRAZIONE';

    			vm.isValidating = true;
    			vm.oldStato = 'INVIATO';
    			vm.isSaving = true;

    			Decisione.update(vm.decisione, onSaveSuccess, onSaveError);

    		} else if (vm.decisione.stato == 'INVIATO_A_DIRETTORE') {
    			vm.decisione.stato = 'RIFIUTATO_DA_DIRETTORE';

    			vm.isValidating = true;
    			vm.oldStato = 'INVIATO_A_DIRETTORE';
    			vm.isSaving = true;

    			Decisione.update(vm.decisione, onSaveSuccess, onSaveError);

    		}
        }

        // inca$ - gestione save, preview o send
        function disableSave () {

        	if (vm.decisione.stato == null) {
        		return false;
        	}

        	// permetto l'amministrazione di inserire il protocollo
        	if (!disableProtocollo())
        		return false;

        	return disableSavePreviewOrSend();
        }

        // inca$ - gestione save, preview o send
        function disablePreviewOrSend () {

        	if (vm.decisione.stato == null) {
        		return true;
        	}
        	return disableSavePreviewOrSend();

        }

        // inca$ - gestione save, preview o send
        function disableSavePreviewOrSend () {

           	// ricordiamoci che il direttore potrebbe essere che sia lui a creare la decisione di contrattare
           	// oppure sia lui il responsabile del procedimento, pertanto va prima verificato questa condizione
           	// successivamente quella del ruolo direttore
           	if (vm.decisione.responsabileProcedimento.user.login == vm.utenteLogin ||
				vm.decisione.utenteCreazione == vm.utenteLogin||
				Permissions.hasRole('ROLE_SUPERUTENTE')) {

				if ((vm.decisione.stato == 'INVIATO')||
						(vm.decisione.stato == 'INVIATO_A_DIRETTORE')||
						(vm.decisione.stato == 'VALIDATO')) {
					return true;
				} else {
					return false;
				}
    		} else if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_DIRETTORE']) &&
    					(vm.decisione.stato == 'INVIATO_A_DIRETTORE' ||
    					vm.decisione.stato == 'VALIDATO'))) {
    			return true;
    		}

           	return true;
        }

        // inca$ - gestione valida o respingi
        function disableValidateOrReject () {

			if (vm.decisione.stato == null)
				return true;

			if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_SUPERUTENTE']) && vm.decisione.stato == 'INVIATO') ||
   				(Permissions.hasOneOfRoles(['ROLE_DIRETTORE','ROLE_SUPERUTENTE']) && vm.decisione.stato == 'INVIATO_A_DIRETTORE')) {
    			return false;
    		}
       		return true;
        }

        // inca$ - gestione campo motivazione rifiuto
        function showMotivazioneRifiuto () {

			if (vm.decisione.stato == null)
				return false;

			if (vm.decisione.motivazioneRifiuto != null)
				return true;

			if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_SUPERUTENTE','ROLE_DIRETTORE']) ||
					(vm.decisione.stato == 'RIFIUTATO_DA_AMMINISTRAZIONE' || vm.decisione.stato == 'RIFIUTATO_DA_DIRETTORE')))
    			return true;

			return false;
        }

        // inca$ - gestione campo protocollo
        function showProtocollo () {

			if (vm.decisione.stato == null)
				return false;

			if (vm.decisione.documentoPdf==null)
				return false;

			if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_SUPERUTENTE']) ||
					vm.decisione.documentoPdf.numeroProtocollo !=null))
    			return true;

			return false;
        }

        // inca$ - gestione campo protocollo
        function disableProtocollo () {

			if (vm.decisione.stato == null)
				return true;

			if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_SUPERUTENTE']) &&
					vm.decisione.stato == 'VALIDATO'))
    			return false;

			return true;
        }

        // inca$ - aggiunta gestione rifiuto
        function rejectDialogForm() {
        	$uibModal.open({
                templateUrl: 'app/util/entity-reject.html',
                controller: 'EntityRejectController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    controller: function() {
                        return vm;
                    },
                    entityName: function() {
                        return 'decisione';
                    }
                }
            }).result.then(function() {
            	vm.reject();
            }, function() {
            });
        }

        function eraseHiddenFields () {

        	if (vm.decisione.spesa!=null &&
        			vm.decisione.spesa<40000) {
        		vm.decisione.spesaAutoritaVigilanza=null;
        	}
        }

        function docFileName (decisione, fileContentType) {
        	var extn = null;
        	if (decisione.documentoPdf.fileContentType!=null)
        		extn = decisione.documentoPdf.fileContentType.split("/").pop();
        	else
        		extn = fileContentType.split("/").pop();
        	return findAndReplace(decisione.impegno.codice,"/","_").replace(/[|&;$%@"<>()+,]/g, "")+"_decisione_ID_"+decisione.id+"."+extn;
        }

        function findAndReplace(string, target, replacement) {
        	 var i = 0, length = string.length;
        	 for (i; i < length; i++) {
        	   string = string.replace(target, replacement);
        	 }
        	 return string;
        }
    
        function viewUpdateStato () {
    		
        	if (vm.decisione.stato == null)
    			return false;

    		if (Permissions.hasOneOfRoles(['ROLE_SUPERUTENTE'])) 
    			return true;
    		
    		return false;
        }

        function updateStato () {
        	AdminCommands.updateStatoDecisione(vm, vm.decisione.id, vm.statoForUpdate);
        }

        function updateStatoDone (result) {
        	$uibModalInstance.dismiss('cancel');
        }

        function findImpegniAggiuntivi () {
        	if (vm.decisione.id!=null) 
        		vm.impegniAggiuntivi = ImpegnoDBSearch.query({size: 100000000, param: {decisioneId: vm.decisione.id}}, dettagliOnSuccess);
            
        	function dettagliOnSuccess(data, headers) {
            }

        }

        function addImpegnoAggiuntivo () {
        	
        	$uibModal.open({
              templateUrl: 'app/entities/decisione/decisione-impegni.html',
              controller: 'DecisioneImpegniController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('impegno');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    controller: function() {
                        return vm;
                    },
                    decisione: function() {
                        return vm.decisione;
                    } 
                }
            }).result.then(function() {
            	vm.findImpegniAggiuntivi();
            	vm.loadImpegniList();
            }, function() {
            	vm.findImpegniAggiuntivi();
            	vm.loadImpegniList();
            });
        }

        function deleteImpegnoAggiuntivo (impegno) {
            
        	$uibModal.open({
                templateUrl: 'app/entities/decisione/decisione-impegno-delete-dialog.html',
                controller: 'DecisioneImpegnoDeleteController',                       
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('impegno');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return impegno;
                    }
                }
            }).result.then(function() {
            	vm.findImpegniAggiuntivi();
            	vm.loadImpegniList();
            }, function() {
            	vm.findImpegniAggiuntivi();
            	vm.loadImpegniList();
            });

        }
        
        // inca$ - gestione inserimento allegati e dettagli
        function disableInsertDetails () {

        	if (disableSave())
        		return true;
        	
        	if (vm.decisione.stato == null) {
        		return true;
        	}
			if (vm.decisione.stato != 'IN_COMPILAZIONE') {
				return true;
			} else {
				return false;
			}

        }

    
    }
})();
