(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('FunzioneController', FunzioneController);

    FunzioneController.$inject = ['$scope', '$state', 'Funzione', 'FunzioneSearch'];

    function FunzioneController ($scope, $state, Funzione, FunzioneSearch) {
        var vm = this;
        
        vm.funziones = [];
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            Funzione.query(function(result) {
                vm.funziones = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            FunzioneSearch.query({query: vm.searchQuery}, function(result) {
                vm.funziones = result;
            });
        }
    }
})();
