(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('DocumentoDialogController', DocumentoDialogController);

    DocumentoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Documento', 'Decisione', 'Dichiarazione', 'DocumentoFile'];

    function DocumentoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Documento, Decisione, Dichiarazione, DocumentoFile) {
        var vm = this;

        vm.documento = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.openStorageFile = openStorageFile;
        vm.save = save;
//        vm.decisiones = Decisione.query();
//        vm.dichiaraziones = Dichiarazione.query(); // non serve in quanto non rendiamo possibile cambiare l'aggancio alla dichiarazione

        // inca$ - caricamento file solo se da DB
        {
        	if (entity.file!=null&&!entity.simpleStorageService)
	    		DocumentoFile.get({id : entity.id}, function(result) {
	     			vm.documento["file"]=result.file;
	            });
        }

        function openStorageFile (type, data) {
        	if (entity.simpleStorageService) {
        		DocumentoFile.get({id : entity.id}, function(result) {
        			//console.log(result.fileContentType);
        			//console.log(result.file);
         			vm.openFile(result.fileContentType, result.file);
                }, onGetFileError);
        	} else
            	vm.openFile(type, data);
        }

        function onGetFileError () {
        	modalScrollTop();
        };	

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.documento.id !== null) {
                Documento.update(vm.documento, onSaveSuccess, onSaveError);
            } else {
                Documento.save(vm.documento, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ordiniApp:documentoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setFile = function ($file, documento) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        documento.file = base64Data;
                        documento.fileContentType = $file.type;
                    });
                });
            }
        };
        vm.datePickerOpenStatus.dataCreazione = false;
        vm.datePickerOpenStatus.dataAggiornamento = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
