(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('GaeDialogController', GaeDialogController);

    GaeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Gae', 'Decisione', 'Finanziamento', 'Struttura'];

    function GaeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Gae, Decisione, Finanziamento, Struttura) {
        var vm = this;

        vm.gae = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.decisiones = Decisione.query();
        vm.finanziamentos = Finanziamento.query({size:100000000}); // inca$ - caricamento totale liste fk
        vm.strutturas = Struttura.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.gae.id !== null) {
                Gae.update(vm.gae, onSaveSuccess, onSaveError);
            } else {
                Gae.save(vm.gae, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ordiniApp:gaeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataCreazione = false;
        vm.datePickerOpenStatus.dataAggiornamento = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
