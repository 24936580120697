(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('FinanziamentoDeleteController',FinanziamentoDeleteController);

    FinanziamentoDeleteController.$inject = ['$uibModalInstance', 'entity', 'Finanziamento'];

    function FinanziamentoDeleteController($uibModalInstance, entity, Finanziamento) {
        var vm = this;

        vm.finanziamento = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Finanziamento.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
