(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('DocumentoAttachController', DocumentoAttachController);

    DocumentoAttachController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'Permissions', 'entity', 'Documento', 'Decisione', 'Dichiarazione', 'controller', 'entityName'];

    // inca$ - derivato da DocumentoDialogController
    function DocumentoAttachController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, Permissions, entity, Documento, Decisione, Dichiarazione, controller, entityName) {
        var vm = this;

        vm.documento = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.decisiones = Decisione.query();
        vm.dichiaraziones = Dichiarazione.query();
        vm.controller = controller;
        vm.entityName = entityName;
        vm.showProtocollo = showProtocollo;
        vm.disableProtocollo = disableProtocollo;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {

        	entity[vm.entityName]=vm.controller[vm.entityName];
        	vm.isSaving = true;
            if (vm.documento.id !== null) {
                Documento.update(vm.documento, onSaveSuccess, onSaveError);
            } else {
                Documento.save(vm.documento, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ordiniApp:documentoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setFile = function ($file, documento) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        documento.file = base64Data;
                        documento.fileContentType = $file.type;
                    });
                });
            }
        };

        // inca$ - gestione campo protocollo
        function showProtocollo () {

			if (vm.controller[vm.entityName].stato == null)
				return false;

			if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_SUPERUTENTE']) ||
					vm.controller[vm.entityName].numeroProtocollo !=null))
    			return true;

			return false;
        }

        // inca$ - gestione campo protocollo
        function disableProtocollo () {

			if (vm.controller[vm.entityName].stato == null)
				return true;

			if ((Permissions.hasOneOfRoles(['ROLE_AMMINISTRAZIONE','ROLE_SUPERUTENTE']) &&
					vm.controller[vm.entityName].stato == 'VALIDATO'))
    			return false;

			return true;
        }
    }
})();
