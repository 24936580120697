(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('ModelloDialogController', ModelloDialogController);

    ModelloDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Modello', 'Struttura'];

    function ModelloDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Modello, Struttura) {
        var vm = this;

        vm.modello = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.strutturas = Struttura.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.modello.id !== null) {
                Modello.update(vm.modello, onSaveSuccess, onSaveError);
            } else {
                Modello.save(vm.modello, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ordiniApp:modelloUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setFile = function ($file, modello) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        modello.file = base64Data;
                        modello.fileContentType = $file.type;
                    });
                });
            }
        };
        vm.datePickerOpenStatus.dataCreazione = false;
        vm.datePickerOpenStatus.dataAggiornamento = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
