// inca$ - questa classe l'ho aggiunta per la gestione personalizzata dei campi in autocomplete
function TypeaheadCtrl($scope, $http, $state) {

  var _selected;

  $scope.selected = undefined;
  // Any function returning a promise object can be used to load values asynchronously
  $scope.getValue = function(val) {
	var name = document.activeElement.name;
	if (val.length<=10) {
		//var funzione = $state.current.parent;
		var funzione = $scope.functionNameForAutocomplete;
		return $http.get('api/'+funzione+'s/'+name, {
	//      params: {
	//        address: val,
	//        sensor: false
	//      }
	    }).then(function(response){
	      return response.data.map(function(item){
	        //return item.fondiProgetto;
	    	eval("var responseList = "+"item."+name);
	    	return responseList;
	      });
	    });
	}
	else {
		return null;
	}
  };

  $scope.ngModelOptionsSelected = function(value) {
    if (arguments.length) {
      _selected = value;
    } else {
      return _selected;
    }
  };

  $scope.modelOptions = {
    debounce: {
      default: 500,
      blur: 250
    },
    getterSetter: true
  };

}

TypeaheadCtrl.$inject = ["$scope", "$http", "$state"];

angular.module('ordiniApp').controller('TypeaheadCtrl', TypeaheadCtrl);