(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('StrutturaDeleteController',StrutturaDeleteController);

    StrutturaDeleteController.$inject = ['$uibModalInstance', 'entity', 'Struttura'];

    function StrutturaDeleteController($uibModalInstance, entity, Struttura) {
        var vm = this;

        vm.struttura = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Struttura.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
