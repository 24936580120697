(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('UtenteDialogController', UtenteDialogController);

    UtenteDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'Utente', 'User', 'Progetto', 'Finanziamento', 'Decisione', 'Struttura', 'UtenteFirma'];

    function UtenteDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, Utente, User, Progetto, Finanziamento, Decisione, Struttura, UtenteFirma) {
        var vm = this;

        vm.utente = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.users = User.query({size:100000000}); // inca$ - caricamento totale liste fk
        //vm.progettos = Progetto.query();
        //vm.finanziamentos = Finanziamento.query();
        //vm.decisiones = Decisione.query();
        vm.strutturas = Struttura.query();
        
        // inca$ - caricamento file firma
        {
	        if (entity.id!=null)
		        UtenteFirma.get({id : entity.id}, function(result) {
		 			vm.utente["firma"]=result.firma;
		        });
	        else
	        	vm.utente["firma"]=null;
        }	        
	        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.utente.id !== null) {
                Utente.update(vm.utente, onSaveSuccess, onSaveError);
            } else {
                Utente.save(vm.utente, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ordiniApp:utenteUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.setFirma = function ($file, utente) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        utente.firma = base64Data;
                        utente.firmaContentType = $file.type;
                    });
                });
            }
        };
        vm.datePickerOpenStatus.dataInizioDirezione = false;
        vm.datePickerOpenStatus.dataFineDirezione = false;
        vm.datePickerOpenStatus.dataCreazione = false;
        vm.datePickerOpenStatus.dataAggiornamento = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
