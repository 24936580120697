(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('UtenteDetailController', UtenteDetailController);

    UtenteDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Utente', 'User', 'Progetto', 'Finanziamento', 'Decisione', 'Struttura', 'UtenteFirma'];

    function UtenteDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Utente, User, Progetto, Finanziamento, Decisione, Struttura, UtenteFirma) {
        var vm = this;

        vm.utente = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        // inca$ - caricamento file firma
		UtenteFirma.get({id : entity.id}, function(result) {
 			vm.utente["firma"]=result.firma;
        });

		var unsubscribe = $rootScope.$on('ordiniApp:utenteUpdate', function(event, result) {
            vm.utente = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
