(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('DettaglioOrdineDialogController', DettaglioOrdineDialogController);

    DettaglioOrdineDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ordineItems', 'DettaglioOrdine', 'Ordine'];

    function DettaglioOrdineDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ordineItems, DettaglioOrdine, Ordine) {
        var vm = this;

        vm.dettaglioOrdine = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        //vm.ordines = Ordine.query(); // non serve in quanto non rendiamo possibile cambiare l'aggancio all'ordine
    	//vm.isFromOrdine=(vm.dettaglioOrdine.ordine!=null)&&(vm.dettaglioOrdine.id==null); // in modo che il campo dettaglioOrdine.ordine non sia visibile se si proviene da Ordine
        vm.isFromOrdine = isFromOrdine;
        vm.ordineItems = ordineItems;
        $scope.functionNameForAutocomplete = 'dettaglio-ordine'; // per gestire l'autocompletamento
        
        {
        	if (isFromOrdine()) {
                if (vm.dettaglioOrdine.id == null) {
                	vm.dettaglioOrdine.percentualeIva=ordineItems.percentualeIva;
                }
        	} 
        }
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
        	
            vm.isSaving = true;
            if (vm.dettaglioOrdine.id !== null) {
                DettaglioOrdine.update(vm.dettaglioOrdine, onSaveSuccess, onSaveError);
            } else {
                DettaglioOrdine.save(vm.dettaglioOrdine, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('ordiniApp:dettaglioOrdineUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataCreazione = false;
        vm.datePickerOpenStatus.dataAggiornamento = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        
        function isFromOrdine () {
        	// inca$ - il service ordineItems, se chiamato dalle modal in OrdineDialogController
        	// assume il valore dell'entità Ordine caricata nella modal, per cui id è valorizzato
        	if (ordineItems.id!=null)
        		return true;
       		
        	return false;
        }

    }
})();
