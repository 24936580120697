(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .factory('StorageService', StorageService);

    StorageService.$inject = ['$resource'];

    function StorageService ($resource) {
        var service = $resource('management/storage/:id', {}, {
            'get': {
                method: 'GET',
                isArray: true
            },
            'query': {
                method: 'GET',
                isArray: true,
                params: {fromDate: null, toDate: null}
	        }
        });

        return service;
    }
})();
