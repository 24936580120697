(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('StrutturaDetailController', StrutturaDetailController);

    StrutturaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Struttura', 'Impegno', 'Gae', 'Utente', 'Modello'];

    function StrutturaDetailController($scope, $rootScope, $stateParams, previousState, entity, Struttura, Impegno, Gae, Utente, Modello) {
        var vm = this;

        vm.struttura = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('ordiniApp:strutturaUpdate', function(event, result) {
            vm.struttura = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
