(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .factory('DataUtils', DataUtils);

    DataUtils.$inject = ['$window'];

    function DataUtils ($window) {

        var service = {
            abbreviate: abbreviate,
            byteSize: byteSize,
            openFile: openFile,
            downloadFile: downloadFile,
            previewFile: previewFile,
            toBase64: toBase64
        };

        return service;

        function abbreviate (text) {
            if (!angular.isString(text)) {
                return '';
            }
            if (text.length < 30) {
                return text;
            }
            return text ? (text.substring(0, 15) + '...' + text.slice(-10)) : '';
        }

        function byteSize (base64String) {
            if (!angular.isString(base64String)) {
                return '';
            }

            function endsWith(suffix, str) {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            }

            function paddingSize(base64String) {
                if (endsWith('==', base64String)) {
                    return 2;
                }
                if (endsWith('=', base64String)) {
                    return 1;
                }
                return 0;
            }

            function size(base64String) {
                return base64String.length / 4 * 3 - paddingSize(base64String);
            }

            function formatAsBytes(size) {
                return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
            }

            return formatAsBytes(size(base64String));
        }

        function openFile (type, data) {
        	// inca$ - non più funzionante su chrome a causa dell'errore
        	// Not allowed to navigate top frame to data URL
            //$window.open('data:' + type + ';base64,' + data, '_blank', 'height=300,width=400');
        	// inoltre la dimensione del data: in alcuni browser non deve superare dei limiti, per Chrome è 2MB
        	// vedi https://stackoverflow.com/questions/695151/data-protocol-url-size-limitations
        	
        	var bBlobUrl = true;
        	
        	var byteCharacters = atob(data);
        	if (byteCharacters.length < 1024 * 1024) // il limite è di 2 MB, però lo poniamo a 1 MB
        		bBlobUrl = false;
        	else {
        		bBlobUrl = true;

            	var byteNumbers = new Array(byteCharacters.length);
            	for (var i = 0; i < byteCharacters.length; i++) {
            	    byteNumbers[i] = byteCharacters.charCodeAt(i);
            	}
            	var byteArray = new Uint8Array(byteNumbers);
            	var blob = new Blob([byteArray], {type: type});
            	var blobUrl = window.URL.createObjectURL(blob);
        	}
        	
			if (bBlobUrl)
				var srcUrl = 'src="'+blobUrl+'" ';
			else
				var srcUrl = 'src="data:'+type+';base64,'+data+'" ';

        	var win = window.open();
        	win.document.write('<iframe '+
        			'width="'+(window.innerWidth-20)+'" '+
        			'height="'+(window.innerHeight-20)+'" '+
        			'id="pdfFrame" '+
        			'frameborder="0" '+
        			srcUrl+
        			//'sandbox="allow-same-origin allow-scripts allow-popups allow-forms" '+
        			'allowfullscreen>'+
        			'</iframe>');
        	
			if (bBlobUrl)
				window.URL.revokeObjectURL(blobUrl);
        }

        // inca$ - download file
        function downloadFile (type, data, filename) {

        	var bBlobUrl = true;
        	
        	var byteCharacters = atob(data);
        	if (byteCharacters.length < 1024 * 1024) // il limite è di 2 MB, però lo poniamo a 1 MB
        		bBlobUrl = false;
        	else {
        		bBlobUrl = true;

            	var byteNumbers = new Array(byteCharacters.length);
            	for (var i = 0; i < byteCharacters.length; i++) {
            	    byteNumbers[i] = byteCharacters.charCodeAt(i);
            	}
            	var byteArray = new Uint8Array(byteNumbers);
            	var blob = new Blob([byteArray], {type: type});
            	var blobUrl = window.URL.createObjectURL(blob);
        	}
        	
			if (bBlobUrl) {
	        	$("<a />", {
	          	  	  href: blobUrl,
	            	  download: filename
	            	})
	            	.appendTo("body")
	            	.get(0)
	            	.click();
			} else {
	        	$("<a />", {
	            	  href: 'data:' + type + ';base64,' + data,
	            	  download: filename
	            	})
	            	.appendTo("body")
	            	.get(0)
	            	.click();
			}

			if (bBlobUrl)
				window.URL.revokeObjectURL(blobUrl);

        }

        
        // inca$ - preview file
        function previewFile (type, data) {
	        
        	var bBlobUrl = true;
        	
        	var byteCharacters = atob(data);
        	if (byteCharacters.length < 1024 * 1024) // il limite è di 2 MB, però lo poniamo a 1 MB
        		bBlobUrl = false;
        	else {
        		bBlobUrl = true;

            	var byteNumbers = new Array(byteCharacters.length);
            	for (var i = 0; i < byteCharacters.length; i++) {
            	    byteNumbers[i] = byteCharacters.charCodeAt(i);
            	}
            	var byteArray = new Uint8Array(byteNumbers);
            	var blob = new Blob([byteArray], {type: type});
            	var blobUrl = window.URL.createObjectURL(blob);
        	}
        	
			if (bBlobUrl)
				var srcUrl = 'src="'+blobUrl+'" ';
			else
				var srcUrl = 'src="data:'+type+';base64,'+data+'" ';

			var win = window.open();
	    	win.document.write('<iframe '+
	    			'width="'+(window.innerWidth-20)+'" '+
	    			'height="'+(window.innerHeight-20)+'" '+
	    			'id="pdfFrame" '+
	    			'frameborder="0" '+
	    			srcUrl+
	    			'allowfullscreen>'+
	    			'</iframe>');

	    	if (bBlobUrl)
				window.URL.revokeObjectURL(blobUrl);
	    	
        }
        
        function toBase64 (file, cb) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                cb(base64Data);
            };
        }
    }
})();
