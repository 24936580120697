(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .factory('Currencies', Currencies);

    Currencies.$inject = ['$http'];

    function Currencies ($http) {
        var service = {
        	loadCurrencyList: loadCurrencyList
        };

        var vm = this;
        return service;
        
        function loadCurrencyList (vmp) {
            return $http.get('api/util/currencies').then(getList);

            function getList (response) {
            	vmp.currencies=response.data;
            	return response;
            }
        }
    }
})();
