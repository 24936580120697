(function () {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('StorageDialogController', StorageDialogController);

    StorageDialogController.$inject = ['$filter', '$uibModalInstance', 'StorageMigrationService', 'controller', 'goType'];

    function StorageDialogController($filter, $uibModalInstance, StorageMigrationService, controller, goType) {
        var vm = this;

        vm.controller=controller;
        vm.clear = clear;
        vm.confirmMigration = confirmMigration;
        vm.disabled=false;
        vm.selCounter=0;

        function disabled() {
            $uibModalInstance.dismiss('cancel');
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmMigration() {
        	//console.log(goType);
    		//console.log(vm.controller.storageSelectedItems);
            vm.disabled=true;

        	if (goType=='all') {
	            var dateFormat = 'yyyy-MM-dd';
	            var fromDate = $filter('date')(controller.fromDate, dateFormat);
	            var toDate = $filter('date')(controller.toDate, dateFormat);

	            StorageMigrationService.migrateByDate({fromDate: fromDate, toDate: toDate}, function(result, headers){
	                //vm.storage = result;
	                $uibModalInstance.close(true);
        			controller.onChangeDate();
	            });
        	} else if (goType=='selected') {
        		
                var totalItems = controller.storageSelectedItems.length;
                vm.selCounter=totalItems;
                var storageItems=[];
                for(var i=0; i < totalItems; i++) {
                	var decisione = controller.storageSelectedItems[i].decisione;

                	StorageMigrationService.migrate({id: decisione.id}, function(result, headers) {
    	                //vm.storage = result;
                		vm.selCounter--;
                		if (vm.selCounter==0) {
                            $uibModalInstance.close(true);
                			controller.onChangeDate();
                		}
    	            });
                }
        	}
        }
    }
})();
