(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .factory('miSearchService', miSearchService);

    miSearchService.$inject = ['$http'];

    function miSearchService ($http) {
        var service = {
        	getDomain: getDomain
        };

        var vm = this;
        vm.controller=null;

        return service;
        
        
        function getDomain (controller) {
        	vm.controller=controller;
        	var domainName=vm.controller.domain;
        	if (domainName!=null) {
            	var radix="ordiniApp"+".";
            	var fileName=domainName.substring(radix.length,domainName.length)+".json";
            	var fileName=fileName.substr(0,1).toLowerCase()+fileName.substr(1);
                return $http.get('i18n/it/'+fileName).then(getDomainValues);
        	} else
        		return null;

            function getDomainValues (response) {
            	vm.controller.updateDomain(response.data);
            	return response;
            }
        }

    }
})();
