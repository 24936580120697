(function() {
    'use strict';
    angular
        .module('ordiniApp')
        .factory('DocumentoFile', DocumentoFile);

    DocumentoFile.$inject = ['$resource', 'DateUtils'];

    function DocumentoFile ($resource, DateUtils) {
        var resourceUrl =  'api/documentos/file/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dataCreazione = DateUtils.convertDateTimeFromServer(data.dataCreazione);
                        data.dataAggiornamento = DateUtils.convertDateTimeFromServer(data.dataAggiornamento);
                    }
                    return data;
                }
            }/*, il salvataggio è disabilitato poichè questo service ha il solo scopo di reperire il file mancante
            'update': { method:'PUT' }*/
        });
    }
})();
