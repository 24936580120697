(function() {
    'use strict';

    angular
        .module('ordiniApp')
        .controller('DichiarazioneDetailController', DichiarazioneDetailController);

    DichiarazioneDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Dichiarazione', 'Documento', 'Decisione', 'DocumentoFile'];

    function DichiarazioneDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Dichiarazione, Documento, Decisione, DocumentoFile) {
        var vm = this;

        vm.dichiarazione = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.openStorageFile = openStorageFile;

        function openStorageFile (documento) {
        	var	type = documento.fileContentType;
        	var data = documento.file;
        	
        	if (documento.simpleStorageService) {
        		DocumentoFile.get({id : documento.id}, function(result) {
        			console.log(result.fileContentType);
        			//console.log(result.file);
         			vm.openFile(result.fileContentType, result.file);
                }, onGetFileError);
        	} else
            	vm.openFile(type, data);
        }

        function onGetFileError () {
        	modalScrollTop();
        };	

        // inca$ - caricamento file solo se da DB
        {
        	if (entity.documentoPdf!=null&&!entity.documentoPdf.simpleStorageService)
	    		DocumentoFile.get({id : entity.documentoPdf.id}, function(result) {
	     			vm.dichiarazione.documentoPdf["file"]=result.file;
	            });
        }

        var unsubscribe = $rootScope.$on('ordiniApp:dichiarazioneUpdate', function(event, result) {
            vm.dichiarazione = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
